import PropTypes from 'prop-types'
// material
import { Paper, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

// ----------------------------------------------------------------------

const SearchNotFound = ({ ...other }) => {
  const { t } = useTranslation()

  return (
    <Paper {...other}>
      <Typography gutterBottom align="center" variant="subtitle1">
        {t('error.no_data')}
      </Typography>
    </Paper>
  )
}
SearchNotFound.propTypes = {
  searchQuery: PropTypes.string
}

export default SearchNotFound
