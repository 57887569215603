import { useEffect, useState } from 'react'

import Avatar from '@mui/material/Avatar'
import Stack from '@mui/material/Stack'
import { Menu, MenuItem, Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'

const Catalog = ({ reloadData, catalogo, handleUnidad }) => {
  const [selected, setSelected] = useState(0)
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState(null)
  const [open, setOpen] = useState(false)
  const [fuente, setFuente] = useState('')

  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget)
    setOpen(true)
    setSelected(id)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setOpen(false)
  }

  const handleFuente = (val) => {
    setFuente(val)
    handleClose()
    handleUnidad(val)
    reloadData(selected)
  }

  useEffect(() => {
    setSelected(catalogo)
  }, [catalogo])

  return (
    <Stack direction="row" spacing={2}>
      <Tooltip title={t('dash.uso_energia')} placement="top">
        <div>
          <Avatar
            sx={{ bgcolor: selected === 2 ? '#F7CA18' : '#D4D4D4', cursor: 'pointer', padding: 1 }}
            variant="rounded"
            src="/static/icons/energy.svg"
            fill="#F7CA18"
            alt={t('dash.uso_energia')}
            onClick={(e) => handleClick(e, 2)}
          />
          <Menu
            anchorEl={anchorEl}
            open={open && selected === 2}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button'
            }}
          >
            <MenuItem onClick={() => handleFuente('Electricidad')}>
              {t('dash.Electricidad')}
            </MenuItem>
            <MenuItem onClick={() => handleFuente('GLP')}>{t('dash.GLP')}</MenuItem>
            <MenuItem onClick={() => handleFuente('Diésel')}>{t('dash.Diésel')}</MenuItem>
          </Menu>
        </div>
      </Tooltip>
      <Tooltip title={t('dash.uso_agua')} placement="top">
        <div>
          <Avatar
            alt={t('dash.uso_agua')}
            sx={{ bgcolor: selected === 3 ? '#F7CA18' : '#D4D4D4', cursor: 'pointer', padding: 1 }}
            variant="rounded"
            src="/static/icons/agua.svg"
            onClick={(e) => handleClick(e, 3)}
          />
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open && selected === 3}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button'
            }}
          >
            <MenuItem onClick={() => handleFuente('Agua')}>{t('dash.Agua')}</MenuItem>
            <MenuItem onClick={() => handleFuente('% Recycled')}>{t('dash.% Recycled')}</MenuItem>
          </Menu>
        </div>
      </Tooltip>
      <Tooltip title={t('dash.residuos_solidos')} placement="top">
        <div>
          <Avatar
            alt={t('dash.residuos_solidos')}
            sx={{ bgcolor: selected === 5 ? '#F7CA18' : '#D4D4D4', cursor: 'pointer', padding: 1 }}
            variant="rounded"
            src="/static/icons/recicle.svg"
            onClick={(e) => handleClick(e, 5)}
          />
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open && selected === 5}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button'
            }}
          >
            <MenuItem onClick={() => handleFuente('Relleno sanitario')}>
              {t('dash.Relleno sanitario')}
            </MenuItem>
            <MenuItem onClick={() => handleFuente('Residuos reciclados')}>
              {t('dash.Residuos reciclados')}
            </MenuItem>
            <MenuItem onClick={() => handleFuente('Orgánicos')}>{t('dash.Orgánicos')}</MenuItem>
            <MenuItem onClick={() => handleFuente('Compost')}>{t('dash.Compost')}</MenuItem>
            <MenuItem onClick={() => handleFuente('Total Waste')}>{t('dash.Total Waste')}</MenuItem>
            <MenuItem onClick={() => handleFuente('% Recycled')}>{t('dash.% Recycled')}</MenuItem>
          </Menu>
        </div>
      </Tooltip>
      <Tooltip title={t('dash.emisiones_gei')} placement="top">
        <div>
          <Avatar
            alt={t('dash.emisiones_gei')}
            sx={{ bgcolor: selected === 4 ? '#F7CA18' : '#D4D4D4', cursor: 'pointer', padding: 1 }}
            variant="rounded"
            src="/static/icons/carbon.svg"
            onClick={(e) => handleClick(e, 4)}
          />
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open && selected === 4}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button'
            }}
          >
            <MenuItem onClick={() => handleFuente('CO2 Emisiones')}>
              {t('dash.CO2 Emisiones')}
            </MenuItem>
          </Menu>
        </div>
      </Tooltip>
      <Tooltip title={t('dash.cadena_suministro')} placement="top">
        <div>
          <Avatar
            alt={t('dash.cadena_suministro')}
            sx={{ bgcolor: selected === 6 ? '#F7CA18' : '#D4D4D4', cursor: 'pointer', padding: 1 }}
            variant="rounded"
            src="/static/icons/cadena_suministro.svg"
            onClick={(e) => handleClick(e, 6)}
          />
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open && selected === 6}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button'
            }}
          >
            <MenuItem onClick={() => handleFuente('Fuentes sostenible')}>
              {t('dash.Fuente sostenible')}
            </MenuItem>
            <MenuItem onClick={() => handleFuente('Proveedores sostenibles')}>
              {t('dash.Proveedores sostenibles')}
            </MenuItem>
            <MenuItem onClick={() => handleFuente('Proveedores certificados')}>
              {t('dash.Proveedores certificados')}
            </MenuItem>
          </Menu>
        </div>
      </Tooltip>
      <Tooltip title={t('dash.compromiso_comunitario')} placement="top">
        <div>
          <Avatar
            alt={t('dash.compromiso_comunitario')}
            sx={{ bgcolor: selected === 7 ? '#F7CA18' : '#D4D4D4', cursor: 'pointer', padding: 1 }}
            variant="rounded"
            src="/static/icons/comunitario.svg"
            onClick={(e) => handleClick(e, 7)}
          />
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open && selected === 7}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button'
            }}
          >
            <MenuItem onClick={() => handleFuente('Servicio comunitario')}>
              {t('dash.Servicio comunitario')}
            </MenuItem>
            <MenuItem onClick={() => handleFuente('Servicio con habilidades')}>
              {t('dash.Servicio con habilidades')}
            </MenuItem>
            <MenuItem onClick={() => handleFuente('Donación en efectivo')}>
              {t('dash.Donación en efectivo')}
            </MenuItem>
            <MenuItem onClick={() => handleFuente('Donación en especie')}>
              {t('dash.Donación en especie')}
            </MenuItem>
            <MenuItem onClick={() => handleFuente('Árboles sembrados')}>
              {t('dash.Árboles sembrados')}
            </MenuItem>
          </Menu>
        </div>
      </Tooltip>
      <Tooltip title={t('dash.compromiso_huesped')} placement="top">
        <div>
          <Avatar
            alt={t('dash.compromiso_huesped')}
            sx={{ bgcolor: selected === 8 ? '#F7CA18' : '#D4D4D4', cursor: 'pointer', padding: 1 }}
            variant="rounded"
            src="/static/icons/travel.svg"
            onClick={(e) => handleClick(e, 8)}
          />
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open && selected === 8}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button'
            }}
          >
            <MenuItem onClick={() => handleFuente('Encuestas de satisfacción')}>
              {t('dash.Encuestas de satisfacción')}
            </MenuItem>
            <MenuItem onClick={() => handleFuente('Nivel de Sostenibilidad')}>
              {t('dash.Nivel de Sostenibilidad')}
            </MenuItem>
          </Menu>
        </div>
      </Tooltip>
      <Tooltip title={t('dash.recursos_humanos')} placement="top">
        <div>
          <Avatar
            title={t('dash.recursos_humanos')}
            sx={{ bgcolor: selected === 10 ? '#F7CA18' : '#D4D4D4', cursor: 'pointer', padding: 1 }}
            variant="rounded"
            src="/static/icons/team.svg"
            onClick={(e) => handleClick(e, 10)}
          />
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open && selected === 10}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button'
            }}
          >
            <MenuItem onClick={() => handleFuente('Total de Colaboradores')}>
              {t('dash.Total de Colaboradores')}
            </MenuItem>
            <MenuItem onClick={() => handleFuente('Total gerentes')}>
              {t('dash.Total rotación')}
            </MenuItem>
            <MenuItem onClick={() => handleFuente('Igualdad de Género')}>
              {t('dash.Igualdad de Género')}
            </MenuItem>
            <MenuItem onClick={() => handleFuente('Rotación voluntaria')}>
              {t('dash.Rotación voluntaria')}
            </MenuItem>
            <MenuItem onClick={() => handleFuente('Rotación involuntaria')}>
              {t('dash.Rotación involuntaria')}
            </MenuItem>
            <MenuItem onClick={() => handleFuente('Accidentes')}>{t('dash.Accidentes')}</MenuItem>
            <MenuItem onClick={() => handleFuente('Ausentismo asociado')}>
              {t('dash.Ausentismo asociado')}
            </MenuItem>
            <MenuItem onClick={() => handleFuente('Total Rotaciones')}>
              {t('dash.Total Rotaciones')}
            </MenuItem>
          </Menu>
        </div>
      </Tooltip>
      <Tooltip title={t('dash.inversiones_sostenibles')} placement="top">
        <div>
          <Avatar
            alt={t('dash.inversiones_sostenibles')}
            sx={{ bgcolor: selected === 9 ? '#F7CA18' : '#D4D4D4', cursor: 'pointer', padding: 1 }}
            variant="rounded"
            src="/static/icons/inversion.svg"
            onClick={(e) => handleClick(e, 9)}
          />
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open && selected === 9}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button'
            }}
          >
            <MenuItem onClick={() => handleFuente('Fuente sostenible')}>
              {t('dash.Fuente sostenible')}
            </MenuItem>
          </Menu>
        </div>
      </Tooltip>
      <Tooltip title={t('dash.metricas_propiedad')} placement="top">
        <div>
          <Avatar
            alt={t('dash.metricas_propiedad')}
            sx={{ bgcolor: selected === 1 ? '#F7CA18' : '#D4D4D4', cursor: 'pointer', padding: 1 }}
            variant="rounded"
            src="/static/icons/propiedad.svg"
            onClick={(e) => handleClick(e, 1)}
          />
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open && selected === 1}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button'
            }}
          >
            <MenuItem onClick={() => handleFuente('Habitaciones ocupadas')}>
              {t('dash.Habitaciones ocupadas')}
            </MenuItem>
          </Menu>
        </div>
      </Tooltip>
    </Stack>
  )
}
export default Catalog
