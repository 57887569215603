import API from '../Api'

class AuthService {
  static login(data) {
    return API({
      method: 'POST',
      url: '/login/',
      data
    })
  }
}

export default AuthService
