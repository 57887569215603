import { Link as RouterLink } from 'react-router-dom'
// material
import { styled } from '@mui/material/styles'
import { Stack, Link, Container, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
// layouts
import AuthLayout from '../layouts/AuthLayout'
// components
import Page from '../components/Page'
import { MHidden } from '../components/@material-extend'
import Restore from '../components/authentication/restore'

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}))

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}))

// ----------------------------------------------------------------------

const RestorePassword = () => {
  const { t } = useTranslation()

  return (
    <RootStyle title="Login">
      <AuthLayout>
        {t('login.dont_have_account')} &nbsp;
        <Link underline="none" variant="subtitle2" component={RouterLink} to="/register">
          {t('login.get_started')}
        </Link>
      </AuthLayout>

      <Container maxWidth="sm">
        <ContentStyle>
          <Stack sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              {t('login.change_password')}
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              {t('register.forgot_password')}
            </Typography>
          </Stack>
          <Restore />
          <MHidden width="smUp">
            <Typography variant="body2" align="center" sx={{ mt: 3 }}>
              {t('login.dont_have_account')} &nbsp;
              <Link variant="subtitle2" component={RouterLink} to="register">
                {t('login.get_started')}
              </Link>
            </Typography>
          </MHidden>
        </ContentStyle>
      </Container>
    </RootStyle>
  )
}

export default RestorePassword
