/* eslint-disable react/jsx-no-constructed-context-values */
import React, { useEffect, useState } from 'react'
// routes
import Router from './routes'
// theme
import ThemeConfig from './theme'
import GlobalStyles from './theme/globalStyles'
// components
import ScrollToTop from './components/ScrollToTop'
import { BaseOptionChartStyle } from './components/charts/BaseOptionChart'
import AuthContext from './context/AuthContext'

// ----------------------------------------------------------------------

const App = () => {
  const [user, setUser] = useState('')
  const [token, setToken] = useState('')

  useEffect(() => {
    const user = localStorage.getItem('user')
    if (user) {
      try {
        const userValue = JSON.parse(user)
        setUser(userValue.user)
        setToken(userValue.token)
      } catch (e) {
        console.log(e)
      }
    }
  }, [])

  const setLoggedUser = async () => {
    const data = window.localStorage.getItem('user')
    if (data) {
      const dat = JSON.parse(data)
      setToken(dat.token)
      setUser(dat.user)
    }
  }

  return (
    <ThemeConfig>
      <AuthContext.Provider
        value={{
          user,
          token,
          updateToken: setLoggedUser
        }}
      >
        <ScrollToTop />
        <GlobalStyles />
        <BaseOptionChartStyle />
        <Router />
      </AuthContext.Provider>
    </ThemeConfig>
  )
}

export default App
