import { useEffect, useState } from 'react'
import { LoadingButton } from '@mui/lab'
import * as Yup from 'yup'
import Select from '@mui/material/Select'
import FormControl from '@mui/material/FormControl'
import { toast } from 'react-toastify'
import { Form, FormikProvider, useFormik } from 'formik'
import { useNavigate, useParams } from 'react-router-dom'
import PropTypes from 'prop-types'

import {
  Box,
  Stack,
  Container,
  TextField,
  InputLabel,
  MenuItem,
  FormHelperText,
  Card,
  Grid
} from '@mui/material'
import { styled } from '@mui/material/styles'

// components

import { useTranslation } from 'react-i18next'
import UserService from '../../../services/UserService'
import Page from '../../../components/Page'
import HeaderComponent from '../../../components/HeaderComponent'
import Title from '../../../components/Title'
import { withAccessGranted, userAccess } from '../../../components/withAuth'
//

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 800,
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(4),
  justifyContent: 'center'
}))

// ----------------------------------------------------------------------

const UserForm = ({ token = null }) => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()
  const RegisterSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, t('error.too_short'))
      .max(50, t('error.too_long'))
      .required(t('user.name_required')),
    email: Yup.string().email(t('user.correo_incorrecto')).required(t('user.email_required')),
    user_type: Yup.number().required(t('user.rol_required'))
  })

  const handleForm = async (values) => {
    try {
      setLoading(true)
      if (!id) {
        await UserService.createUsers(values, token)
      } else {
        await UserService.updateUsers(id, values, token)
      }
      setLoading(false)
      toast(t('error.operacion_exito'), {
        type: toast.TYPE.SUCCESS
      })
      navigate('/users/list', { replace: true })
    } catch ({ response }) {
      setLoading(false)
      if (response.data.error) {
        toast(t(`error.${response.data.error}`), {
          type: toast.TYPE.ERROR
        })
      } else {
        toast(t(`error.error`), {
          type: toast.TYPE.ERROR
        })
      }
    }
  }

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      user_type: null
    },
    validationSchema: RegisterSchema,
    onSubmit: handleForm
  })

  const [usersType, setUsersType] = useState([])
  const getData = async () => {
    try {
      setLoading(true)
      const usersTest = await UserService.getUsersType(token)
      setUsersType(usersTest.data)
      console.log(id)
      if (id) {
        const user = await UserService.getUsersById(id, token)
        formik.setValues({
          name: user.data.name,
          email: user.data.email,
          user_type: user.data.user_type.id
        })
      }
      setLoading(false)
    } catch ({ response }) {
      setLoading(false)
      if (response?.data?.error) {
        toast(t(`error.${response.data.error}`), {
          type: toast.TYPE.ERROR
        })
      } else {
        toast(t(`error.error`), {
          type: toast.TYPE.ERROR
        })
      }
    }
  }

  useEffect(() => {
    if (token) {
      getData()
    }
  }, [token])

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, values } = formik

  return (
    <Page title={t('menu.user')} loading={loading}>
      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Title title={t('user.new_user')} />
        </Stack>
        <HeaderComponent />
        <Card>
          <ContentStyle>
            <Box>
              <h3>{t('user.informacion_usuario')}</h3>
            </Box>
            <Box sx={{ mb: 4, mt: 2 }}>
              <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                  <Stack spacing={3}>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                      <TextField
                        required
                        fullWidth
                        label={t('user.nombre_usuario')}
                        {...getFieldProps('name')}
                        name="name"
                        error={Boolean(touched.name && errors.name)}
                        helperText={touched.name && errors.name}
                      />
                      {usersType && (
                        <FormControl fullWidth required>
                          <InputLabel id="rol" shrink={values.user_type}>
                            {t('user.tipo_usuario')}
                          </InputLabel>
                          <Select
                            labelId="rol"
                            id="user_type"
                            {...getFieldProps('user_type')}
                            label={t('user.tipo_usuario')}
                            name="user_type"
                            error={Boolean(touched.user_type && errors.user_type)}
                            helperText={touched.user_type && errors.user_type}
                          >
                            {usersType.map((user) => (
                              <MenuItem key={`key_${user.id}`} value={user.id}>
                                {user.nombre}
                              </MenuItem>
                            ))}
                          </Select>
                          <FormHelperText error={touched.user_type && errors.user_type}>
                            {errors.user_type}
                          </FormHelperText>
                        </FormControl>
                      )}
                    </Stack>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                      <TextField
                        fullWidth
                        required
                        label={t('user.email_acceso')}
                        {...getFieldProps('email')}
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                      />
                    </Stack>
                    <Box sx={{ flexGrow: 1 }}>
                      <Grid container xs={12} spacing={2} alignItems="center">
                        <Grid item xs={4} alignItems="center">
                          <LoadingButton
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            loading={isSubmitting}
                          >
                            {t('user.guardar')}
                          </LoadingButton>
                        </Grid>
                      </Grid>
                    </Box>
                  </Stack>
                </Form>
              </FormikProvider>
            </Box>
          </ContentStyle>
        </Card>
      </Container>
    </Page>
  )
}

UserForm.propTypes = {
  token: PropTypes.string.isRequired
}

export default withAccessGranted(UserForm, [
  userAccess.ADMIN.ACCESS_LEVEL,
  userAccess.REGISTRO.ACCESS_LEVEL
])
