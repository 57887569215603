import { useState } from 'react'
import { Outlet } from 'react-router-dom'
// material
import { styled } from '@mui/material/styles'
//
import Navbar from './Navbar'
import Sidebar from './Sidebar'
import Footer from './Footer'

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64
const APP_BAR_DESKTOP = 92

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
})

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}))

// ----------------------------------------------------------------------

const DashboardLayout = ({ type }) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <RootStyle>
        <Navbar onOpenSidebar={() => setOpen(true)} type={type} />
        <Sidebar isOpenSidebar={open} type={type} onCloseSidebar={() => setOpen(false)} />
        <MainStyle>
          <Outlet />
        </MainStyle>
      </RootStyle>
      <Footer />
    </>
  )
}

export default DashboardLayout
