import { useRef, useState } from 'react'
import { Person, AccountCircle } from '@mui/icons-material'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
// material
import { alpha } from '@mui/material/styles'
import { Button, Box, Divider, MenuItem, Typography, Avatar, IconButton } from '@mui/material'
// components
import { useTranslation } from 'react-i18next'
import MenuPopover from '../../components/MenuPopover'
import AuthContext from '../../context/AuthContext'

//

const AccountPopover = () => {
  const { t } = useTranslation()
  const anchorRef = useRef(null)
  const [open, setOpen] = useState(false)
  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const logaut = () => {
    localStorage.removeItem('user')
    localStorage.removeItem('token')
    window.location = 'http://www.iqlodging.com/'
  }

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
            }
          })
        }}
      >
        <Avatar color="#15BD85">
          <AccountCircle color="#15BD85" bg="#15BD85" />
        </Avatar>
      </IconButton>
      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <AuthContext.Consumer>
          {({ user }) => (
            <Box sx={{ my: 1.5, px: 2.5 }}>
              <Typography variant="subtitle1" noWrap>
                {user.name}
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                {user.email}
              </Typography>
            </Box>
          )}
        </AuthContext.Consumer>
        <Divider sx={{ my: 1 }} />
        <MenuItem
          to="/users/profile"
          component={RouterLink}
          onClick={handleClose}
          sx={{ typography: 'body2', py: 1, px: 2.5 }}
        >
          <Person /> {t('menu.gestion_usuario')}
        </MenuItem>
        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button fullWidth color="inherit" onClick={logaut} variant="outlined">
            {t('menu.logout')}
          </Button>
        </Box>
      </MenuPopover>
    </>
  )
}

export default AccountPopover
