import { Link as RouterLink } from 'react-router-dom'
// material
import { styled } from '@mui/material/styles'
import { Box, Link, Container, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

// layouts
import AuthLayout from '../layouts/AuthLayout'
// components

import Page from '../components/Page'
import { MHidden } from '../components/@material-extend'
import { RegisterForm } from '../components/authentication/register'

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}))

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 600,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}))

// ----------------------------------------------------------------------

const Register = () => {
  const { t } = useTranslation()

  return (
    <RootStyle title="Register">
      <AuthLayout>
        <Link underline="none" variant="subtitle2" component={RouterLink} to="/login">
          {t('login.login')}
        </Link>
      </AuthLayout>
      <Container>
        <ContentStyle>
          <Box sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              {t('register.register_reate_account')}
            </Typography>
          </Box>
          <RegisterForm />
          <Typography
            i18nKey="register.tem_conditions"
            variant="body2"
            align="center"
            sx={{ color: 'text.secondary', mt: 3 }}
          >
            {t('register.registering_agree')}
            &nbsp;
            <Link underline="always" sx={{ color: 'text.primary' }}>
              {t('register.terms_of_service')}
            </Link>
            &nbsp;and&nbsp;
            <Link underline="always" sx={{ color: 'text.primary' }}>
              {t('register.privacy_policy')}
            </Link>
            .
          </Typography>

          <MHidden width="smUp">
            <Typography variant="subtitle2" sx={{ mt: 3, textAlign: 'center' }}>
              {t('register.already_have_account')}&nbsp;
              <Link to="/login" component={RouterLink}>
                {t('login.login')}
              </Link>
            </Typography>
          </MHidden>
        </ContentStyle>
      </Container>
    </RootStyle>
  )
}

export default Register
