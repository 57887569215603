import { el } from 'date-fns/locale'
import API from '../Api'

class AdminService {
  static getCountry(token) {
    return API({
      method: 'GET',
      url: '/api/v1/admin/country/',
      headers: {
        Authorization: `Token ${token}`
      }
    })
  }

  static getTypeLodging(token) {
    return API({
      method: 'GET',
      url: '/api/v1/admin/type_lodging/',
      headers: {
        Authorization: `Token ${token}`
      }
    })
  }

  static updateOrganization(id, data, token) {
    return API({
      method: 'PUT',
      url: `/api/v1/admin/organization/${id}/`,
      data,
      headers: {
        Authorization: `Token ${token}`
      }
    })
  }

  static getOrganization(id, token) {
    return API({
      method: 'GET',
      url: `/api/v1/admin/organization/${id}/`,
      headers: {
        Authorization: `Token ${token}`
      }
    })
  }

  static getGreenOrganization(token) {
    return API({
      method: 'GET',
      url: '/api/v1/property/green_organization/',
      headers: {
        Authorization: `Token ${token}`
      }
    })
  }

  static getProperties(token) {
    return API({
      method: 'GET',
      url: '/api/v1/property/property/',
      headers: {
        Authorization: `Token ${token}`
      }
    })
  }

  static getDataTrimestral(data, token) {
    return API({
      method: 'GET',
      url: `/api/v1/dashboard/trimester/?id_property=${data.id}&year=${data.year}&trimester=${data.trimestre}`,
      headers: {
        Authorization: `Token ${token}`
      }
    })
  }

  static getDataIndicador(id, year, year2, catalogo, token) {
    return API({
      method: 'GET',
      url: `api/v1/dashboard/kpi_property/?id_property=${id}&year=${year}&year1=${year2}&category=${catalogo}`,
      headers: {
        Authorization: `Token ${token}`
      }
    })
  }

  static getDataIndicadorRoom(id, year, catalogo, token) {
    return API({
      method: 'GET',
      url: `api/v1/dashboard/kpi_ocupation_rooms/?id_property=${id}&year=${year}&category=${catalogo}`,
      headers: {
        Authorization: `Token ${token}`
      }
    })
  }

  static getDataIndicadorYear(id, year, year2, catalogo, token) {
    return API({
      method: 'GET',
      url: `api/v1/dashboard/kpi_consolidate/?id_property=${id}&year=${year}&year1=${year2}&category=${catalogo}`,
      headers: {
        Authorization: `Token ${token}`
      }
    })
  }

  static getDataIndicadorYearConsolidate(id, year, year2, catalogo, token) {
    return API({
      method: 'GET',
      url: `api/v1/dashboard/reporte_consolidate/?id_property=${id}&year1=${year}&year2=${year2}&category=${catalogo}`,
      headers: {
        Authorization: `Token ${token}`
      }
    })
  }

  static getDataIndicadorMensual(id, year, year2, catalogo, token) {
    return API({
      method: 'GET',
      url: `api/v1/dashboard/reporte_mensual/?id_property=${id}&year1=${year}&year2=${year2}&category=${catalogo}`,
      headers: {
        Authorization: `Token ${token}`
      }
    })
  }

  static getDataIndicadorConsolidate(year, token) {
    return API({
      method: 'GET',
      url: `api/v1/dashboard/kpi/?id_property=56&year=${year}`,
      headers: {
        Authorization: `Token ${token}`
      }
    })
  }

  static getAllYear(token, project) {
    return API({
      method: 'GET',
      url: `/api/v1/dashboard/year/?id_property=${project}`,
      headers: {
        Authorization: `Token ${token}`
      }
    })
  }

  static getProperty(id, token) {
    return API({
      method: 'GET',
      url: `/api/v1/property/property/${id}/`,
      headers: {
        Authorization: `Token ${token}`
      }
    })
  }

  static getTemplateProperty(id, token) {
    return API({
      method: 'GET',
      url: `/api/v1/property/template_property/${id}/`,
      headers: {
        Authorization: `Token ${token}`
      }
    })
  }

  static postProperty(data, token) {
    return API({
      method: 'POST',
      url: `/api/v1/property/property/`,
      data,
      headers: {
        Authorization: `Token ${token}`
      }
    })
  }

  static putProperty(data, id, token) {
    return API({
      method: 'PUT',
      url: `/api/v1/property/property/${id}/`,
      data,
      headers: {
        Authorization: `Token ${token}`
      }
    })
  }

  static getPropertyType(token) {
    return API({
      method: 'GET',
      url: '/api/v1/admin/type_property/',
      headers: {
        Authorization: `Token ${token}`
      }
    })
  }

  static deletePropertyCategory(id, token) {
    return API({
      method: 'DELETE',
      url: `/api/v1/property/property/${id}/`,
      headers: {
        Authorization: `Token ${token}`
      }
    })
  }
}

export default AdminService
