import { createContext } from 'react'

const newUser = () => ({
  email: '',
  name: '',
  tipo_usuario: 0,
  expiresIn: 0,
  organization: 0
})

const AuthContext = createContext({
  token: '',
  user: newUser()
})

export default AuthContext
