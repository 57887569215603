import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'
// material
import { styled } from '@mui/material/styles'
// components
import Logo from '../components/common/Logo'
//

// ----------------------------------------------------------------------

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: 'auto',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7)
  }
}))

// ----------------------------------------------------------------------

AuthLayout.propTypes = {
  children: PropTypes.node
}

export default function AuthLayout({ children }) {
  return (
    <HeaderStyle>
      <RouterLink to="/">
        <Logo />
      </RouterLink>
    </HeaderStyle>
  )
}
