import React, { Component } from 'react'
import { Navigate } from 'react-router-dom'
import AuthContext from '../../context/AuthContext'

const userAccess = {
  ADMIN: {
    NAME: 'Administrador',
    ACCESS_LEVEL: 1
  },
  ADMINUSER: {
    NAME: 'Administrador Usuarios',
    ACCESS_LEVEL: 2
  },
  REGISTRO: {
    NAME: 'Registro',
    ACCESS_LEVEL: 3
  },
  CONSULTA: {
    NAME: 'Consulta',
    ACCESS_LEVEL: 4
  }
}

Object.freeze(userAccess)

function withAccessGranted(WrappedComponent, privilege) {
  return class extends Component {
    constructor(props) {
      super(props)
      this.state = {
        accessLevelRequired: privilege
      }
    }

    render() {
      const { accessLevelRequired } = this.state
      return (
        <AuthContext.Consumer>
          {({ user, token }) => {
            const role = true // accessLevelRequired.find((item) => item === user.user_type)
            const props = {
              user,
              token
            }
            return (
              <div>
                {role ? (
                  <WrappedComponent {...this.props} {...props} />
                ) : (
                  <Navigate push to="/login" />
                )}
              </div>
            )
          }}
        </AuthContext.Consumer>
      )
    }
  }
}
export { withAccessGranted, userAccess }
