import PropTypes from 'prop-types'
// material
import { TableRow, TableCell, TableHead } from '@mui/material'
import { useTranslation } from 'react-i18next'

// ----------------------------------------------------------------------

const UserListHead = ({ headLabel }) => {
  const { t } = useTranslation()
  return (
    <TableHead>
      <TableRow>
        {headLabel.map((headCell) => (
          <TableCell key={headCell.id}>{t(`user.${headCell.label}`)}</TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}
UserListHead.propTypes = {
  headLabel: PropTypes.array
}

export default UserListHead
