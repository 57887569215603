import ReactApexChart from 'react-apexcharts'
// material
import { PropTypes } from 'prop-types'
import { Card, CardHeader, Box } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
//

// ----------------------------------------------------------------------

const Consolidado = ({ data, nameProperty, name, unidadMedida = 'kWh', year1, year2 }) => {
  const { t } = useTranslation()
  const [subheader, setSubheader] = useState('')
  const [chartData, setChartData] = useState()
  const [series, setSeries] = useState()
  useEffect(() => {
    if (data) {
      try {
        setSubheader(`${t(`dash.${name}`)} - (${t(`register.${unidadMedida}`)})`)
        const { valor, valor1 } = data
        const meses = [
          year1.toString(),
          year2.toString(),
          `${t('dash.diferencia')} (${year1} - ${year2})`
        ]
        const series = [
          {
            data: [
              parseFloat(parseFloat(valor).toFixed(2)),
              parseFloat(parseFloat(valor1).toFixed(2)),
              parseFloat(parseFloat(valor - valor1).toFixed(2))
            ]
          }
        ]
        setSeries(series)
        const chartOptions = {
          chart: {
            type: 'bar',
            height: 350,
            toolbar: {
              show: true,
              offsetX: 0,
              offsetY: 0,
              tools: {
                download: true,
                selection: false,
                zoom: false,
                zoomin: false,
                zoomout: false,
                pan: false,
                reset: false
              },
              autoSelected: 'zoom'
            }
          },
          title: {
            text: `${subheader} ${nameProperty}`
          },
          colors: ['#808080', '#F7CA18', '#07bc0c'],
          plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: true,
              columnWidth: '45%',
              distributed: true,
              dataLabels: {
                position: 'top'
              }
            }
          },
          dataLabels: {
            enabled: true,
            offsetX: -6,
            style: {
              fontSize: '12px'
            }
          },
          xaxis: {
            categories: meses,
            labels: {
              style: {
                colors: ['#808080', '#F7CA18', '#07bc0c'],
                fontSize: '12px'
              }
            }
          }
        }
        setChartData(chartOptions)
      } catch (error) {
        console.log(error)
      }
    }
  }, [data])

  return (
    <Card>
      <CardHeader title={`KPIs - ${nameProperty}`} subheader={subheader || ''} />
      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <div id="chart">
          {chartData && (
            <ReactApexChart options={chartData} series={series} type="bar" height={350} />
          )}
        </div>
      </Box>
    </Card>
  )
}
Consolidado.propType = {
  data: PropTypes.object
}
Consolidado.defaultProps = {
  trimestre: 0,
  data: {}
}

export default Consolidado
