import { Link as RouterLink } from 'react-router-dom'
// material
import { useTranslation } from 'react-i18next'

import { styled } from '@mui/material/styles'
import { Stack, Link, Container, Typography } from '@mui/material'
// layouts
import AuthLayout from '../../layouts/AuthLayout'
// components
import Page from '../../components/Page'
import LoginForm from './components'
import Footer from '../../components/Footer'
import LanguagePopover from '../../layouts/dashboard/LanguagePopover'

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}))

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
  [theme.breakpoints.down('md')]: {
    minHeight: '10vh'
  }
}))

// ----------------------------------------------------------------------

export default function Login() {
  const { t } = useTranslation()

  return (
    <>
      <div style={{ position: 'relative', width: '90%', margin: '10px', textAlign: 'right' }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          spacing={{ xs: 0.5, sm: 1.5 }}
        >
          <LanguagePopover />
        </Stack>
      </div>
      <RootStyle title="Login">
        <AuthLayout />
        <Container maxWidth="xl">
          <ContentStyle>
            <Stack sx={{ mb: 2 }}>
              <Typography variant="h3" gutterBottom align="center">
                {t('login.login')}
              </Typography>
            </Stack>
            <LoginForm />
            <Stack width="smUp">
              <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                {t('login.dont_have_account')}&nbsp;
                <Link variant="subtitle2" component={RouterLink} to="/register">
                  {t('login.get_started')}
                </Link>
              </Typography>
            </Stack>
          </ContentStyle>
        </Container>
      </RootStyle>
      <Footer />
    </>
  )
}
