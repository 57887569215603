import PropTypes from 'prop-types'
import { TableRow, TableCell, TableHead } from '@mui/material'
import { useTranslation } from 'react-i18next'

// ----------------------------------------------------------------------

const PropertyHead = ({ headLabel }) => {
  const { t } = useTranslation()

  return (
    <TableHead>
      <TableRow>
        {headLabel.map((headCell) => (
          <TableCell key={headCell.id} align="left">
            {t(`admin.${headCell.label}`)}
          </TableCell>
        ))}
        <TableCell />
      </TableRow>
    </TableHead>
  )
}

PropertyHead.propTypes = {
  headLabel: PropTypes.array
}
export default PropertyHead
