import { useState } from 'react'
import PropTypes from 'prop-types'
import { Icon } from '@iconify/react'
import { useTranslation } from 'react-i18next'
import { NavLink as RouterLink, matchPath, useLocation } from 'react-router-dom'
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill'
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill'
import { alpha, useTheme, styled } from '@mui/material/styles'
import { Box, List, Collapse, ListItemText, ListItemButton } from '@mui/material'
import AuthContext from '../context/AuthContext'

// ----------------------------------------------------------------------

const ListItemStyle = styled((props) => <ListItemButton disableGutters {...props} />)(
  ({ theme }) => ({
    height: 40,
    position: 'relative',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    color: '#fff',
    '&:before': {
      top: 0,
      right: 0,
      width: 3,
      bottom: 0,
      content: "''",
      display: 'none',
      position: 'absolute',
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
      color: '#fff'
    }
  })
)

// ----------------------------------------------------------------------

const NavItem = ({ item, active }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const isActiveRoot = active(item.path)
  const { title, path, info, children } = item
  const [open, setOpen] = useState(true)

  const handleOpen = () => {
    setOpen((prev) => !prev)
  }

  const activeRootStyle = {
    color: 'primary.main',
    fontWeight: 'fontWeightMedium',
    bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
    '&:before': { display: 'block' }
  }

  const activeSubStyle = {
    color: '#097969'
  }

  if (children) {
    return (
      <>
        <ListItemStyle
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot && activeRootStyle)
          }}
        >
          <ListItemText style={{ color: '#fff' }} disableTypography primary={t(`menu.${title}`)} />
          <Box
            component={Icon}
            style={{ color: '#fff' }}
            icon={open ? arrowIosDownwardFill : arrowIosForwardFill}
            sx={{ width: 16, height: 16, ml: 1 }}
          />
        </ListItemStyle>
        <AuthContext.Consumer>
          {({ user }) => (
            <Collapse in={open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {children
                  .filter((item) => !item.rol || item.rol >= user.user_type)
                  .map((item) => {
                    const { title, path, rol } = item
                    const isActiveSub = active(path)

                    return (
                      <ListItemStyle
                        key={title}
                        component={RouterLink}
                        to={path}
                        sx={{
                          ...(isActiveSub && activeSubStyle),
                          ml: 2
                        }}
                      >
                        <ListItemText disableTypography primary={t(`menu.${title}`)} />
                      </ListItemStyle>
                    )
                  })}
              </List>
            </Collapse>
          )}
        </AuthContext.Consumer>
      </>
    )
  }

  return (
    <ListItemStyle
      component={RouterLink}
      to={path}
      sx={{
        ...(isActiveRoot && activeRootStyle)
      }}
    >
      <ListItemText disableTypography primary={title} />
      {info && info}
    </ListItemStyle>
  )
}

NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.func
}
const NavSection = ({ navConfig, ...other }) => {
  const { pathname } = useLocation()
  const match = (path) => (path ? !!matchPath({ path, end: false }, pathname) : false)
  return (
    <Box {...other}>
      <List disablePadding>
        {navConfig.map((item) => (
          <NavItem key={item.title} item={item} active={match} />
        ))}
      </List>
    </Box>
  )
}

NavSection.propTypes = {
  navConfig: PropTypes.array,
  label: PropTypes.string.isRequired
}
export default NavSection
