import PropTypes from 'prop-types'
import { Icon } from '@iconify/react'
import menu2Fill from '@iconify/icons-eva/menu-2-fill'
// material
import { styled } from '@mui/material/styles'
import Link from '@mui/material/Link'

import { Box, Stack, AppBar, Toolbar, IconButton, Tooltip } from '@mui/material'
// components
import { useTranslation } from 'react-i18next'
import { MHidden } from '../../components/@material-extend'
//
import AccountPopover from './AccountPopover'
import LanguagePopover from './LanguagePopover'
import AuthContext from '../../context/AuthContext'

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280
const APPBAR_MOBILE = 54
const APPBAR_DESKTOP = 92

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  minHeight: '65px',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: 'transparent',
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
  }
}))

const Separator = styled(Box)(({ theme }) => ({
  width: '1px',
  borderLeft: 'solid 1px #F0F0F0',
  height: '41px',
  display: 'inline-block',
  [theme.breakpoints.down('md')]: {
    display: 'none'
  }
}))

const Menuitem = styled(IconButton)(({ theme }) => ({
  '&:hover': {
    backgroundColor: 'transparent'
  },
  [theme.breakpoints.down('md')]: {
    padding: 2
  },
  [theme.breakpoints.up('md')]: {
    padding: 10,
    margin: '0 10px'
  }
}))

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  backgroundColor: '#FFFFFF',
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}))

const IconMenu = styled('img')(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    width: 28,
    height: 28,
    margin: '15px 5px'
  },
  [theme.breakpoints.up('md')]: {
    width: 32,
    height: 32,
    margin: '15px 5px'
  }
}))

// ----------------------------------------------------------------------

const Navbar = ({ onOpenSidebar, type }) => {
  const { t } = useTranslation()

  return (
    <RootStyle>
      <AuthContext.Consumer>
        {({ user, token }) => (
          <ToolbarStyle>
            <MHidden width="lgUp">
              <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
                <Icon icon={menu2Fill} />
              </IconButton>
            </MHidden>
            <Box sx={{ flexGrow: 1 }}>
              <Stack
                direction="row"
                alignItems="center"
                margin="auto"
                style={{ width: 'max-content' }}
              >
                <Link href="/dashboard/app">
                  <Menuitem
                    sx={{
                      padding: 0
                    }}
                  >
                    <Tooltip title={t('dash.dashboard')} placement="top">
                      {type === 'dashboard' ? (
                        <IconMenu src="/static/icons/dashOn.png" alt="Dashboard" />
                      ) : (
                        <IconMenu src="/static/icons/dash.png" alt="Dashboard" />
                      )}
                    </Tooltip>
                  </Menuitem>
                </Link>
                <Separator />
                {user.user_type === 1 ? (
                  <Link href="/users/list">
                    <Menuitem
                      sx={{
                        padding: 0
                      }}
                    >
                      <Tooltip title={t('dash.user')} placement="top">
                        {type === 'users' ? (
                          <IconMenu src="/static/icons/usuarioOn.png" alt="Dashboard" />
                        ) : (
                          <IconMenu src="/static/icons/usuario.png" alt="Dashboard" />
                        )}
                      </Tooltip>
                    </Menuitem>
                  </Link>
                ) : (
                  <Link href="/users/profile">
                    <Menuitem
                      sx={{
                        padding: 0
                      }}
                    >
                      <Tooltip title={t('dash.user')} placement="top">
                        {type === 'users' ? (
                          <IconMenu src="/static/icons/usuarioOn.png" alt="Dashboard" />
                        ) : (
                          <IconMenu src="/static/icons/usuario.png" alt="Dashboard" />
                        )}
                      </Tooltip>
                    </Menuitem>
                  </Link>
                )}
                <Separator />
                {user.user_type === 1 && (
                  <Link href="/admin/community">
                    <Menuitem
                      sx={{
                        padding: 0
                      }}
                    >
                      <Tooltip title={t('dash.comunidad')} placement="top">
                        {type === 'admin' ? (
                          <IconMenu src="/static/icons/adminOn.png" alt="Dashboard" />
                        ) : (
                          <IconMenu src="/static/icons/admin.png" alt="Dashboard" />
                        )}
                      </Tooltip>
                    </Menuitem>
                  </Link>
                )}
                <Separator />
                <Link href="/register/list">
                  <IconButton
                    sx={{
                      padding: 2
                    }}
                  >
                    <Tooltip title={t('dash.register')} placement="top">
                      {type === 'data' ? (
                        <IconMenu src="/static/icons/registroOn.png" alt="Dashboard" />
                      ) : (
                        <IconMenu src="/static/icons/registro.png" alt="Dashboard" />
                      )}
                    </Tooltip>
                  </IconButton>
                </Link>
              </Stack>
            </Box>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              spacing={{ xs: 0.5, sm: 1.5 }}
            >
              <LanguagePopover />
              <AccountPopover />
            </Stack>
          </ToolbarStyle>
        )}
      </AuthContext.Consumer>
    </RootStyle>
  )
}
Navbar.propTypes = {
  onOpenSidebar: PropTypes.func,
  type: PropTypes.string
}
export default Navbar
