// scroll bar
import 'simplebar/dist/simplebar.css'

import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import App from './App'
import * as serviceWorker from './serviceWorker'
import reportWebVitals from './reportWebVitals'
import './i18n'
// ----------------------------------------------------------------------
const Aplication = () => (
  <HelmetProvider>
    <BrowserRouter>
      <App />
      <ToastContainer />
    </BrowserRouter>
  </HelmetProvider>
)

const container = document.getElementById('root')
const root = createRoot(container)
root.render(<Aplication />)
serviceWorker.unregister()
reportWebVitals()
