import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { AppRegistration } from '@mui/icons-material'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'

import {
  Box,
  Stack,
  Container,
  TextField,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Grid,
  IconButton,
  Card,
  InputAdornment
} from '@mui/material'
import { styled } from '@mui/material/styles'

// components
import { Icon } from '@iconify/react'
import searchFill from '@iconify/icons-eva/search-fill'

import Page from '../../../components/Page'
import Scrollbar from '../../../components/Scrollbar'
import UserListHead from './component/ListHead'
//
import HeaderComponent from '../../../components/HeaderComponent'
import AdminService from '../../../services/Admin/index'
import { withAccessGranted, userAccess } from '../../../components/withAuth'

const ContentStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: '20px 0'
}))

const ListProperty = ({ token }) => {
  const { t } = useTranslation()
  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(false)
  const [countries, setCountries] = useState([])
  const [listProperties, setListProperties] = useState([])

  useEffect(() => {
    const loadData = async () => {
      setLoading(true)
      try {
        const [country, property] = await Promise.all([
          AdminService.getCountry(token),
          AdminService.getProperties(token)
        ])
        setListProperties(property.data)
        setCountries(country.data)
        setLoading(false)
      } catch (e) {
        setLoading(false)
        toast(t(`error.error`), {
          type: toast.TYPE.ERROR
        })
      }
    }
    if (token) {
      loadData()
    }
  }, [token])

  const TABLE_HEAD = [
    { id: 'name', label: 'name_property', alignRight: false },
    { id: 'company', label: 'id_property', alignRight: false },
    { id: 'role', label: 'anio_inicio', alignRight: false },
    { id: 'isVerified', label: 'type_property', alignRight: false },
    { id: 'status', label: 'area_contruida', alignRight: false }
  ]

  const handleSearch = async () => {
    if (search.length > 0) {
      const data = listProperties.filter((item) =>
        item.name.toLowerCase().includes(search.toLowerCase())
      )
      setListProperties(data)
    } else {
      try {
        const property = await AdminService.getProperties(token)
        setListProperties(property.data)
      } catch (e) {
        toast(t(`error.error`), {
          type: toast.TYPE.ERROR
        })
      }
    }
  }

  return (
    <Page title={t('menu.propiedades')} loading={loading}>
      <Card>
        <Container maxWidth="xl">
          <ContentStyle>
            <Box sx={{ mb: 5 }}>
              <HeaderComponent type title={t('admin.list_propiedades')} />
              <Box sx={{ mx: 3, marginBottom: 5 }} dir="ltr">
                <Box sx={{ flexGrow: 1, mb: 2 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={6} md={6}>
                      <TextField
                        fullWidth
                        label={t('admin.nombre_propiedad')}
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={handleSearch} edge="end">
                                <Icon icon={searchFill} />
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                    </Grid>
                    <Grid item xs={6} md={3} />
                  </Grid>
                </Box>
                <Stack>
                  <Scrollbar>
                    <TableContainer sx={{ minWidth: 800 }}>
                      <Table>
                        <UserListHead headLabel={TABLE_HEAD} />
                        <TableBody>
                          {listProperties.map((row) => {
                            const { id, name, code, year, type_property: type, area } = row
                            return (
                              <TableRow hover key={id} tabIndex={-1}>
                                <TableCell component="th" scope="row" padding="none">
                                  {name}
                                </TableCell>
                                <TableCell align="left">{code}</TableCell>
                                <TableCell align="left">{year}</TableCell>
                                <TableCell align="left">{t(`register.${type.name}`)}</TableCell>
                                <TableCell align="left">{area}</TableCell>
                                <TableCell>
                                  <Link to={`/register/report/${id}`}>
                                    <AppRegistration />
                                  </Link>
                                </TableCell>
                              </TableRow>
                            )
                          })}
                          {listProperties.length > 0 && (
                            <TableRow style={{ height: 53 }}>
                              <TableCell colSpan={6} />
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Scrollbar>
                </Stack>
              </Box>
            </Box>
          </ContentStyle>
        </Container>
      </Card>
    </Page>
  )
}

ListProperty.propTypes = {
  token: PropTypes.string.isRequired
}

export default withAccessGranted(ListProperty, [
  userAccess.ADMIN.ACCESS_LEVEL,
  userAccess.REGISTRO.ACCESS_LEVEL
])
