import API from '../Api'

class UserService {
  static getUsers(token) {
    return API({
      method: 'GET',
      url: '/api/v1/users/user/',
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
  }

  static getUsersRol(rol, token) {
    return API({
      method: 'GET',
      url: `/user?rol=${rol}`,
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
  }

  static getUsersType(token) {
    return API({
      method: 'GET',
      url: `api/v1/users/tipo_usuario/`,
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
  }

  static getUsersById(id, token) {
    return API({
      method: 'GET',
      url: `/api/v1/users/user/${id}`,
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
  }

  static createUsers(data, token) {
    return API({
      method: 'POST',
      url: '/api/v1/users/user/',
      data,
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
  }

  static updateUsers(id, data, token) {
    return API({
      method: 'PUT',
      url: `/api/v1/users/user/${id}/`,
      data,
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
  }

  static usersChangePass(email, password, token) {
    return API({
      method: 'GET',
      url: `/api/v1/users/cambiar_contrasenna/?email=${email}&password=${password}`,
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
  }

  static deleteUser(id, token) {
    return API({
      method: 'DELETE',
      url: `/api/v1/users/user/${id}/`,
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
  }
}

export default UserService
