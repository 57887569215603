import { Grid, Stack } from '@mui/material'

import styled from '@emotion/styled'

const FooterWrapper = styled('footer')({
  padding: '0 2rem',
  position: 'absolute',
  marginTop: '-35px',
  width: '100%'
})

const Footer = () => (
  <FooterWrapper className="footer">
    <Stack direction="row">
      <Grid container justifyContent="flex-end">
        <Grid item justifyContent="flex-end" alignItems="center">
          <div style={{ margin: '0', textAlign: 'right' }}>
            Powered by Marriott® Costa Rica & CPG® Hospitality
          </div>
        </Grid>
      </Grid>
    </Stack>
  </FooterWrapper>
)
export default Footer
