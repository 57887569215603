import { Typography } from '@mui/material'
import { useTheme, styled } from '@mui/material/styles'

import PropTypes from 'prop-types'

// ----------------------------------------------------------------------
const TitleWrapper = styled(Typography)(({ useTheme }) => ({
  fontFamily: 'Gazpacho-Black',
  fontStyle: 'normal',
  fontWeight: '900',
  fontSize: '21px',
  lineHeight: '25px',
  color: '#454545'
}))

const Title = ({ title = '' }) => <TitleWrapper>{title}</TitleWrapper>

Title.propTypes = {
  title: PropTypes.string
}

export default Title
