import * as Yup from 'yup'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useFormik, Form, FormikProvider } from 'formik'
// material
import { Stack, TextField } from '@mui/material'
import { LoadingButton } from '@mui/lab'

// ----------------------------------------------------------------------

const Reset = () => {
  const navigate = useNavigate()

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required')
  })

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: LoginSchema,
    onSubmit: (value) => {
      console.log(value)
      navigate('/', { replace: true })
    }
  })

  const { errors, touched, values, isSubmitting, getFieldProps } = formik
  const { t } = useTranslation()

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label={t('login.email_address')}
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            {t('register.reset')}
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  )
}

export default Reset
