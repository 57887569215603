import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import { AppRegistration, ArrowBackIos } from '@mui/icons-material'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'

import {
  Box,
  Stack,
  Container,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Card,
  CardHeader,
  Grid,
  IconButton
} from '@mui/material'
import { styled } from '@mui/material/styles'

import Page from '../../../components/Page'
import Scrollbar from '../../../components/Scrollbar'
import SearchNotFound from '../../../components/SearchNotFound'
import UserListHead from './component/ListHead'
import HeaderComponent from '../../../components/HeaderComponent'
import AdminService from '../../../services/Admin/index'
import { withAccessGranted, userAccess } from '../../../components/withAuth'

const Atras = styled(IconButton)(({ theme }) => ({
  margin: theme.spacing(2),
  fontSize: '14px'
}))

const ContentStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: '20px 0'
}))

// ----------------------------------------------------------------------

const ListReport = ({ token }) => {
  const { id } = useParams()
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [selected, setSelected] = useState([])
  const [property, setProperty] = useState({})

  useEffect(() => {
    const loadData = async () => {
      setLoading(true)
      try {
        const prop = await AdminService.getProperty(id, token)
        setProperty(prop.data)
        setLoading(false)
      } catch (err) {
        setLoading(false)
        toast(t(`error.error`), {
          type: toast.TYPE.ERROR
        })
      }
    }
    if (token) {
      loadData()
    }
  }, [token, id])

  const TABLE_HEAD = [
    { id: 'categoria_reporte', label: 'categoria_reporte', alignRight: false },
    { id: 'lista_kepis', label: 'lista_kepis', alignRight: false },
    { id: 'origen_kepis', label: 'origen_kepis', alignRight: false },
    { id: 'datos_entrada', label: 'datos_entrada', alignRight: false }
  ]

  return (
    <Page title={t('menu.propiedades')} loading={loading}>
      <Card>
        <Container maxWidth="xl">
          <ContentStyle>
            <HeaderComponent title={t('register.type_property')} style={{ paddingTop: '0px' }} />
            <Box sx={{ mt: 1 }}>
              <Grid container spacing={3}>
                <Grid>
                  <Link to="/register/list">
                    <Atras>
                      <ArrowBackIos style={{ fontSize: '14px' }} /> {t('register.atras')}
                    </Atras>
                  </Link>
                </Grid>
              </Grid>
            </Box>
            <CardHeader title={`${property.name ? property.name : ''}`} sty />
            <Box sx={{ mx: 1 }}>
              <Box sx={{ mb: 1 }}>
                <Stack>
                  <Scrollbar>
                    <TableContainer sx={{ minWidth: 800 }}>
                      <Table>
                        <UserListHead
                          headLabel={TABLE_HEAD}
                          rowCount={property?.categories?.length}
                          numSelected={selected.length}
                        />
                        {property && property.categories ? (
                          <TableBody>
                            {property?.categories.map((row) => (
                              <TableRow hover key={row.id} tabIndex={-1}>
                                <TableCell component="th" scope="row" padding="1">
                                  {t(`register.${row.report_category.name}`)}
                                </TableCell>
                                <TableCell component="th" scope="row" padding="1">
                                  {t(`register.${row.kpis_list.name}`)}
                                </TableCell>
                                <TableCell component="th" scope="row" padding="1">
                                  {t(`register.${row.source}`)}
                                </TableCell>
                                <TableCell component="th" scope="row" padding="1">
                                  {t(`register.${row.input_data}`)}
                                </TableCell>
                                <TableCell align="right">
                                  <Link to={`/register/data/${row.id}/${id}`}>
                                    <AppRegistration />
                                  </Link>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        ) : (
                          <TableBody>
                            <TableRow style={{ height: 53 * 100 }}>
                              <TableCell colSpan={6} />
                            </TableRow>
                          </TableBody>
                        )}

                        {property?.categories && property?.categories.length === 0 && (
                          <TableBody>
                            <TableRow>
                              <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                <SearchNotFound color="#00AB55" />
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        )}
                      </Table>
                    </TableContainer>
                  </Scrollbar>
                </Stack>
              </Box>
            </Box>
          </ContentStyle>
        </Container>
      </Card>
    </Page>
  )
}

ListReport.propTypes = {
  token: PropTypes.string.isRequired
}

export default withAccessGranted(ListReport, [
  userAccess.ADMIN.ACCESS_LEVEL,
  userAccess.REGISTRO.ACCESS_LEVEL
])
