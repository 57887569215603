import { Navigate, useRoutes } from 'react-router-dom'
// layouts
import DashboardLayout from './layouts/dashboard'
import LogoOnlyLayout from './layouts/LogoOnlyLayout'
//
import Login from './pages/Login'
import Register from './pages/Register'
import Project from './pages/Dashboard/Project'
import UsersList from './pages/Users/List'
import UserForm from './pages/Users/Form'
import UserProfile from './pages/Users/Profile'
import NotFound from './pages/Page404'
import ResetPassword from './pages/ResetPassword'
import RestorePassword from './pages/RestorePassword'
import CommunityForm from './pages/Administration/Community'
import ListProperty from './pages/Administration/ListProperty'
import Consolidate from './pages/Dashboard/Consolidate'
import List from './pages/Register/List'
import ListReport from './pages/Register/ListReport'
import Data from './pages/Register/Data'
import PropertyForm from './pages/Administration/PropertyForm'
import KpisYear from './pages/Dashboard/KpisYear'
import ReporteAnual from './pages/Dashboard/ReporteAnual'
import ReporteMensual from './pages/Dashboard/ReporteMensual'

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout type="dashboard" />,
      children: [
        { element: <Navigate to="/dashboard/app" replace /> },
        { path: 'app', element: <Project /> },
        { path: 'consolidate', element: <Consolidate /> },
        { path: 'year', element: <KpisYear /> },
        { path: 'report-month', element: <ReporteMensual /> },
        { path: 'report-consolidate', element: <ReporteAnual /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    {
      path: '/users',
      element: <DashboardLayout type="users" />,
      children: [
        { element: <Navigate to="/users/all" replace /> },
        { path: 'list', element: <UsersList /> },
        { path: 'user/:id', element: <UserForm /> },
        { path: 'user', element: <UserForm /> },
        { path: 'profile', element: <UserProfile /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    {
      path: '/admin',
      element: <DashboardLayout type="admin" />,
      children: [
        { element: <Navigate to="/admin/community" replace /> },
        { path: 'community', element: <CommunityForm /> },
        { path: 'property/list', element: <ListProperty /> },
        { path: 'property/form/:id', element: <PropertyForm /> },
        { path: 'property/form', element: <PropertyForm /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    {
      path: '/register',
      element: <DashboardLayout type="data" />,
      children: [
        { element: <Navigate to="/register/list" replace /> },
        { path: 'list', element: <List /> },
        { path: 'report/:id', element: <ListReport /> },
        { path: 'data/:id/:project', element: <Data /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'new_organization', element: <Register /> },
        { path: 'login', element: <Login /> },
        { path: 'reset', element: <ResetPassword /> },
        { path: 'restore', element: <RestorePassword /> },
        { path: '404', element: <NotFound /> },
        { path: '/', element: <Navigate to="/login" /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ])
}
