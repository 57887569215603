import { useEffect, useState } from 'react'

import Avatar from '@mui/material/Avatar'
import Stack from '@mui/material/Stack'
import { Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'

const Catalog = ({ reloadData, catalogo }) => {
  const [selected, setSelected] = useState(0)
  const { t } = useTranslation()

  const selecetdItem = (id) => {
    setSelected(id)
    reloadData(id)
  }

  useEffect(() => {
    setSelected(catalogo)
  }, [catalogo])

  return (
    <Stack direction="row" spacing={2}>
      <Tooltip title={t('dash.uso_energia')} placement="top">
        <Avatar
          sx={{ bgcolor: selected === 2 ? '#F7CA18' : '#D4D4D4', cursor: 'pointer', padding: 1 }}
          variant="rounded"
          src="/static/icons/energy.svg"
          fill="#F7CA18"
          alt={t('dash.uso_energia')}
          onClick={() => selecetdItem(2)}
        />
      </Tooltip>
      <Tooltip title={t('dash.uso_agua')} placement="top">
        <Avatar
          alt={t('dash.uso_agua')}
          sx={{ bgcolor: selected === 3 ? '#F7CA18' : '#D4D4D4', cursor: 'pointer', padding: 1 }}
          variant="rounded"
          src="/static/icons/agua.svg"
          onClick={() => selecetdItem(3)}
        />
      </Tooltip>
      <Tooltip title={t('dash.emisiones_gei')} placement="top">
        <Avatar
          alt={t('dash.emisiones_gei')}
          sx={{ bgcolor: selected === 6 ? '#F7CA18' : '#D4D4D4', cursor: 'pointer', padding: 1 }}
          variant="rounded"
          src="/static/icons/carbon.svg"
          onClick={() => selecetdItem(6)}
        />
      </Tooltip>
    </Stack>
  )
}
export default Catalog
