import axios from 'axios'

const BAD_REQUEST = 401

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
})

axiosInstance.interceptors.request.use(
  (config) => config,
  (error) => {
    console.log(`Interceptor Request Error${error}`)
  }
)

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log(`Interceptor Response Error${error}`)
    try {
      if (error.response.status === BAD_REQUEST) {
        localStorage.removeItem('user')
        localStorage.removeItem('login')
        window.location = '/login'
      }
    } catch (e) {
      console.log(`Interceptor Response Error${error}`)
    }
    return Promise.reject(error)
  }
)

export default axiosInstance
