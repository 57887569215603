import React, { useEffect, useState } from 'react'
import { filter } from 'lodash'
import { Icon } from '@iconify/react'
import plusFill from '@iconify/icons-eva/plus-fill'
import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'
// material
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  Box,
  styled
} from '@mui/material'
// components
import { useTranslation } from 'react-i18next'
import Page from '../../../components/Page'
import Scrollbar from '../../../components/Scrollbar'
import SearchNotFound from '../../../components/SearchNotFound'
import { UserListHead, UserListToolbar, UserMoreMenu } from './component'
//
import HeaderComponent from '../../../components/HeaderComponent'
import Title from '../../../components/Title'
import UserService from '../../../services/UserService'
import { withAccessGranted, userAccess } from '../../../components/withAuth'

const ContentStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: '20px 0'
}))

const TABLE_HEAD = [
  { id: 'nombre', label: 'nombre', alignRight: false },
  { id: 'usuario', label: 'usuario', alignRight: false },
  { id: 'tipo_usuario', label: 'tipo_usuario', alignRight: false },
  { id: 'fecha_ultimo_acceso', label: 'fecha_ultimo_acceso', alignRight: false },
  { id: 'acciones', label: 'acciones', alignRight: false }
]

const UserList = ({ token }) => {
  const { t } = useTranslation()
  const [filterName, setFilterName] = useState('')
  const [users, setUsers] = useState([])

  const loadData = async () => {
    const users = await UserService.getUsers(token)
    const filteredUsers = users.data
    setUsers(filteredUsers)
  }

  useEffect(() => {
    if (token) {
      loadData()
    }
  }, [token])

  const isUserNotFound = users.length === 0

  return (
    <Page title={t('menu.user')}>
      <Card>
        <ContentStyle>
          <Container maxWidth="xl">
            <HeaderComponent title={t('user.user_list')} type />

            <Stack direction="row" alignItems="right" justifyContent="space-between" mb={1}>
              <Box sx={{ m: 3 }} />
              <Box sx={{ m: 3 }}>
                <Button
                  variant="contained"
                  component={RouterLink}
                  to="/users/user"
                  startIcon={<Icon icon={plusFill} />}
                >
                  {t('user.new_user')}
                </Button>
              </Box>
            </Stack>
            <Box sx={{ m: 1, mb: 10, mt: 0 }}>
              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <UserListHead headLabel={TABLE_HEAD} />
                    <TableBody>
                      {users.map((row) => {
                        const {
                          id,
                          name,
                          create_date: createDate,
                          user_type: userType,
                          email,
                          isVerified
                        } = row

                        return (
                          <TableRow hover key={id} tabIndex={-1}>
                            <TableCell>
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography variant="subtitle2" noWrap>
                                  {name}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell align="left">{email}</TableCell>
                            <TableCell align="left">
                              {userType && t(`user.${userType.nombre}`)}{' '}
                            </TableCell>
                            <TableCell align="left">
                              {createDate && createDate.substring(0, 10)}
                            </TableCell>
                            <TableCell align="right">
                              <UserMoreMenu {...row} token={token} loadData={loadData} />
                            </TableCell>
                          </TableRow>
                        )
                      })}
                      {!users ||
                        (users.length === 0 && (
                          <TableRow style={{ height: 100 }}>
                            <TableCell colSpan={6} />
                          </TableRow>
                        ))}
                    </TableBody>

                    {isUserNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>
            </Box>
          </Container>
        </ContentStyle>
      </Card>
    </Page>
  )
}

UserList.propTypes = {
  token: PropTypes.string.isRequired
}

export default withAccessGranted(UserList, [
  userAccess.ADMIN.ACCESS_LEVEL,
  userAccess.REGISTRO.ACCESS_LEVEL
])
