import { useEffect, useRef, useState } from 'react'
// material
import { alpha } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import { Box, MenuItem, ListItemText } from '@mui/material'
// components
import i18next from 'i18next'
import MenuPopover from '../../components/MenuPopover'

const LANGS = [
  {
    id: 0,
    value: 'es',
    label: 'Español'
  },
  {
    id: 1,
    value: 'en',
    label: 'English'
  }
]

const LanguagePopover = () => {
  const anchorRef = useRef(null)
  const [open, setOpen] = useState(false)
  const { i18n } = useTranslation()
  const [value, setValue] = useState('es')
  useEffect(() => {
    if (i18n.language.indexOf('en') !== -1) {
      if (value !== 'en') {
        setValue('en')
        i18next.changeLanguage('en')
      }
    } else if (value !== 'es') {
      i18next.changeLanguage('en')
      setValue('es')
    }
  }, [])

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleChange = (e) => {
    if (e.target.textContent === 'English') {
      setValue('en')
      i18next.changeLanguage('en')
    } else {
      setValue('es')
      i18next.changeLanguage('es')
    }
    handleClose()
  }

  return (
    <>
      <Box
        ref={anchorRef}
        onClick={handleOpen}
        style={{ color: '#000', cursor: 'pointer' }}
        sx={{
          padding: 1,
          height: 44,
          ...(open && {
            bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity)
          })
        }}
      >
        {value === 'en' ? 'English' : 'Español'}
      </Box>

      <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current}>
        <Box sx={{ py: 1 }}>
          {LANGS.map((option) => (
            <MenuItem
              key={option.value}
              selected={option.value === value}
              onClick={(e) => handleChange(e)}
              sx={{ py: 1, px: 3.5 }}
            >
              <ListItemText
                primaryTypographyProps={{ variant: 'body2' }}
                value={option.value}
                id={option.value}
              >
                {option.label}
              </ListItemText>
            </MenuItem>
          ))}
        </Box>
      </MenuPopover>
    </>
  )
}

export default LanguagePopover
