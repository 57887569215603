import { Box } from '@mui/material'
import { useTheme, styled } from '@mui/material/styles'

// ----------------------------------------------------------------------
const Headerbar = styled(Box)(({ useTheme }) => ({
  display: 'flex',
  background: 'trasnsparent',
  borderRadius: '5px',
  marginBottom: 50,
  minHeight: '48px',
  borderBottom: '1px solid #e0e0e0',
  '& div': {
    flex: 1,
    display: 'flex'
  }
}))
const Title = styled('h3')(({ useTheme }) => ({
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  marginTop: '10px',
  marginLeft: '5px',
  fontWeight: '600'
}))

const HeaderComponent = ({ title, children, tipe = false }) => (
  <Headerbar>
    <Title>{!tipe ? <b>{title}</b> : <Title>{title}</Title>}</Title>
    <div>{children}</div>
  </Headerbar>
)

export default HeaderComponent
