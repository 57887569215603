import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet-async'
import { forwardRef, useEffect, useState } from 'react'
// material
import { Backdrop, Box, CircularProgress } from '@mui/material'

// ----------------------------------------------------------------------

const Page = forwardRef(({ children, title = '', loading = false, ...other }, ref) => {
  const [loadingValue, setLoadingValue] = useState(true)
  useEffect(() => {
    setLoadingValue(loading)
  }, [loading])
  return (
    <Box ref={ref} {...other}>
      <Backdrop open={loadingValue} style={{ zIndex: '9999' }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Helmet>
        <title>{title} | IQlodging</title>
      </Helmet>
      {children}
    </Box>
  )
})

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  loading: PropTypes.bool
}

export default Page
