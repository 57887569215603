import API from '../Api'

class RegisterService {
  static createYear(data, token) {
    return API({
      method: 'POST',
      url: '/api/v1/foot_print/property_register/',
      data,
      headers: {
        Authorization: `Token ${token}`
      }
    })
  }

  static getData(id, year, mont, token) {
    return API({
      method: 'GET',
      url: `/api/v1/foot_print/property_register/?category=${id}&year=${year}&month=${mont}`,
      headers: {
        Authorization: `Token ${token}`
      }
    })
  }

  static getYears(id, token) {
    return API({
      method: 'GET',
      url: `/api/v1/foot_print/anios/?category=${id}`,
      headers: {
        Authorization: `Token ${token}`
      }
    })
  }

  static reeloadGreen(id, token) {
    return API({
      method: 'GET',
      url: `/api/v1/foot_print/property_register_green/?id=${id}`,
      headers: {
        Authorization: `Token ${token}`
      }
    })
  }

  static reloadAllGreen(property, year, token) {
    return API({
      method: 'GET',
      url: `/api/v1/foot_print/green_integration/?property=${property}&year=${year}`,
      headers: {
        Authorization: `Token ${token}`
      }
    })
  }

  static handleValue(id, value, token) {
    return API({
      method: 'PUT',
      url: `/api/v1/foot_print/property_register/${id}/`,
      data: {
        value
      },
      headers: {
        Authorization: `Token ${token}`
      }
    })
  }

  static getNote(id, token) {
    return API({
      method: 'GET',
      url: `/api/v1/foot_print/property_register_note/?register=${id}`,
      headers: {
        Authorization: `Token ${token}`
      }
    })
  }

  static createNote(data, token) {
    return API({
      method: 'POST',
      url: 'api/v1/foot_print/property_register_note/',
      data,
      headers: {
        Authorization: `Token ${token}`
      }
    })
  }
}

export default RegisterService
