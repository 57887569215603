import PropTypes from 'prop-types'
// material
import { Box } from '@mui/material'

// ----------------------------------------------------------------------

const LogoMenu = ({ sx }) => (
  <Box
    component="img"
    src="/static/logo_white.png"
    style={{ width: 200, height: 'auto', ...sx }}
    sx={{ margin: '30px auto', ...sx }}
  />
)

LogoMenu.propTypes = {
  sx: PropTypes.object
}

export default LogoMenu
