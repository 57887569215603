import PropTypes from 'prop-types'
// material
import { TableRow, TableCell, TableHead, TableSortLabel } from '@mui/material'
import { useTranslation } from 'react-i18next'

// ----------------------------------------------------------------------

const PropertyHead = ({ headLabel, onRequestSort }) => {
  const { t } = useTranslation()

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {headLabel.map((headCell) => (
          <TableCell key={headCell.id} align={headCell.alignRight ? 'right' : 'left'}>
            <TableSortLabel hideSortIcon onClick={createSortHandler(headCell.id)}>
              {t(`admin.${headCell.label}`)}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell />
      </TableRow>
    </TableHead>
  )
}

PropertyHead.propTypes = {
  headLabel: PropTypes.array,
  onRequestSort: PropTypes.func
}
export default PropertyHead
