// material
import { useState, useEffect } from 'react'
import {
  Grid,
  Container,
  Stack,
  Box,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Typography
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { PropTypes } from 'prop-types'
import { withAccessGranted, userAccess } from '../../components/withAuth'

import AdminService from '../../services/Admin/index'
// components
import Page from '../../components/Page'
import Title from '../../components/Title'
import { Trimestral } from './component'
import Catalog from './component/catalog'
import Consolidate from './component/Consolidate'

const KpisYear = ({ token = '' }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState([])
  const [catalogo, setCatalogo] = useState(0)
  const [years, setYears] = useState([])
  const [nameProperty, setNameProperty] = useState('')
  const [typeProperty, setTypeProperty] = useState([])
  const [property, setProperty] = useState()
  const [year, setYear] = useState('')
  const [year2, setYear2] = useState('')
  const [dataIndicator, setDataIndicator] = useState([])
  const [projectName, setProjectName] = useState('')
  const [um, setUm] = useState('')

  const [unidad, setUnidad] = useState('')

  const handleUnidad = (unidad) => {
    setUnidad(unidad)
  }

  const getData = async () => {
    try {
      setLoading(true)
      const typeData = await AdminService.getProperties(token)
      setTypeProperty(typeData.data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }
  const getDataAnual = async (property, year, year2, catalogo) => {
    try {
      setLoading(true)
      const dataIndicador = await AdminService.getDataIndicadorYear(
        property,
        year,
        year2,
        catalogo,
        token
      )
      setDataIndicator(dataIndicador.data.data)
      setNameProperty(dataIndicador.data.name)
      setUm(dataIndicador.data.um)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const reloadData = (catalog) => {
    setCatalogo(catalog)
  }

  useEffect(() => {
    if (token) {
      getData()
    }
  }, [token])

  useEffect(() => {
    if (property && year && year2 && catalogo && unidad) {
      getDataAnual(property, year, year2, catalogo)
    }
  }, [property, year, year2, catalogo, unidad])

  const handleProperty = async (property) => {
    try {
      setLoading(true)
      setProperty(property)
      if (token && token !== '') {
        const yearData = await AdminService.getAllYear(token, property)
        setYears(yearData.data)
        setProjectName(yearData.name)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  return (
    <Page title="Dashboard" loading={loading}>
      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Title title={t('dash.consulta_proyecto')} />
        </Stack>
        <Box sx={{ flexGrow: 1 }} pt={2} pb={2}>
          <Grid container style={{ borderBottom: ' solid 1px #D8D8D8' }}>
            <Grid item xs={3} md={3}>
              <b> {t('dash.seleccione_propiedad')}</b>
            </Grid>
            <Grid item xs={3} paddingLeft={2}>
              <FormControl fullWidth>
                <InputLabel id="anio">{t('dash.seleccione_una_opcion')}</InputLabel>
                <Select
                  labelId="residuos"
                  id="demo-simple-select"
                  label="Rol"
                  value={property}
                  variant="standard"
                  onChange={(e) => handleProperty(e.target.value)}
                >
                  {typeProperty.map((item) => (
                    <MenuItem key={`item_${item.id}`} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2} ml={2}>
              <FormControl fullWidth>
                <InputLabel id="anio">{t('dash.periodo')}</InputLabel>
                <Select
                  labelId="anio"
                  id="demo-simple-select"
                  label={t('dash.periodo')}
                  variant="standard"
                  value={year}
                  onChange={(e) => setYear(e.target.value)}
                >
                  {years.map((item) => (
                    <MenuItem key={`item_${item.year}`} value={item.year}>
                      {item.year}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2} ml={2}>
              <FormControl fullWidth>
                <InputLabel id="anio">{t('dash.anio_comparar')}</InputLabel>
                <Select
                  labelId="anio2"
                  id="demo-simple-select"
                  label={t('dash.anio_comparar')}
                  variant="standard"
                  value={year2}
                  onChange={(e) => setYear2(e.target.value)}
                >
                  {years.map((item) => (
                    <MenuItem key={`item_${item.year}`} value={item.year}>
                      {item.year}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        <Grid container spacing={3} mt={4}>
          <Grid item xs={12} md={12} lg={12}>
            <Catalog reloadData={reloadData} catalogo={catalogo} handleUnidad={handleUnidad} />
          </Grid>
          {dataIndicator.length > 0 && (
            <Grid item xs={12}>
              <Typography variant="h4" gutterBottom>
                {t('dash.kpis_anueles')}
              </Typography>
            </Grid>
          )}
          {dataIndicator &&
            dataIndicator
              .filter((p) => p[0].input_data === unidad)
              .map((item, id) => (
                <Grid item xs={12} md={12} key={`id_${id}`} lg={12}>
                  <Trimestral
                    nameProperty={nameProperty}
                    data={item[0]}
                    year1={year}
                    year2={year2}
                    project={projectName}
                    unidadMedida={um}
                    name={item[0].input_data}
                  />
                </Grid>
              ))}
          {dataIndicator &&
            dataIndicator
              .filter((p) => p[0].input_data === unidad)
              .map((item, id) => (
                <Grid item xs={12} key={`id2_${id}`}>
                  <Consolidate
                    nameProperty={nameProperty}
                    data={item[0]}
                    year1={year}
                    year2={year2}
                    unidadMedida={um}
                    name={item[0].input_data}
                  />
                </Grid>
              ))}
        </Grid>
      </Container>
    </Page>
  )
}
KpisYear.propTypes = {
  token: PropTypes.string
}

export default withAccessGranted(KpisYear, [
  userAccess.ADMIN.ACCESS_LEVEL,
  userAccess.REGISTRO.ACCESS_LEVEL
])
