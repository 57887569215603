import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import Select from '@mui/material/Select'
import FormControl from '@mui/material/FormControl'
import { useTranslation } from 'react-i18next'
import { Form, FormikProvider, useFormik } from 'formik'
import { useNavigate, useParams } from 'react-router-dom'
import { LoadingButton } from '@mui/lab'
import PropTypes from 'prop-types'

// material
import {
  Box,
  Stack,
  Container,
  TextField,
  InputLabel,
  MenuItem,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Grid,
  IconButton,
  TableHead
} from '@mui/material'
import { styled } from '@mui/material/styles'

// components

import { Delete } from '@mui/icons-material'
import { toast } from 'react-toastify'
import Page from '../../../components/Page'
import Scrollbar from '../../../components/Scrollbar'
import HeaderComponent from '../../../components/HeaderComponent'
import Title from '../../../components/Title'
import AdminService from '../../../services/Admin/index'
import { withAccessGranted, userAccess } from '../../../components/withAuth'

const ContentStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center'
}))

const TitleLabel = styled('h1')(({ useTheme }) => ({
  color: '#FFFFFF',
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: '15px',
  lineHeight: '20px',
  padding: '16px'
}))

// ----------------------------------------------------------------------

const Propiedad = ({ token, user }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { id } = useParams()
  const [country, setCountry] = useState(0)
  const [loading, setLoading] = useState(false)
  const [typeProperty, setTypeProperty] = useState(0)
  const [countries, setCountries] = useState([])
  const [propertyTypes, setPropertyTypes] = useState([])
  const [dataCategory, setDataCategory] = useState([])
  const [greenOrg, setGreenOrg] = useState([])
  const RegisterSchema = Yup.object().shape({
    name: Yup.string(),
    description: Yup.string(),
    year: Yup.number(),
    measure_date: Yup.string(),
    area: Yup.number()
  })

  const handleSubmitProject = async (values) => {
    const { year, description, name, measurement_date: date, area } = values
    const data = {
      name,
      description,
      year,
      country,
      code: '',
      type_property: typeProperty,
      measurement_date: date,
      data_category: dataCategory,
      area
    }
    try {
      if (id) {
        try {
          await AdminService.putProperty(data, id, token)
          toast(t('error.operacion_exito'), {
            type: toast.TYPE.SUCCESS
          })
          navigate('/admin/property/list', { replace: true })
        } catch (e) {
          toast(t(`error.error`), {
            type: toast.TYPE.ERROR
          })
        }
      } else {
        try {
          await AdminService.postProperty(data, token)
          toast(t('error.operacion_exito'), {
            type: toast.TYPE.SUCCESS
          })
          navigate('/admin/property/list', { replace: true })
        } catch (e) {
          toast(t(`error.error`), {
            type: toast.TYPE.ERROR
          })
        }
      }
    } catch (error) {
      toast(t(`error.error`), {
        type: toast.TYPE.ERROR
      })
    }
  }

  const formik = useFormik({
    initialValues: {
      name: '',
      description: null,
      year: '',
      measurement_date: '',
      area: ''
    },
    validationSchema: RegisterSchema,
    onSubmit: handleSubmitProject
  })

  const getData = async () => {
    setLoading(true)
    try {
      const [country, getPropertyType, green] = await Promise.all([
        AdminService.getCountry(token),
        AdminService.getPropertyType(token),
        AdminService.getGreenOrganization(token)
      ])
      setCountries(country.data)
      setPropertyTypes(getPropertyType.data)
      if (user.greencloud) {
        setGreenOrg(green.data)
      }
      setLoading(false)
    } catch (error) {
      toast(t(`error.error`), {
        type: toast.TYPE.ERROR
      })
    }
    if (id) {
      try {
        const data = await AdminService.getProperty(id, token)
        setTypeProperty(data.data.type_property)
        setCountry(data.data.country)
        try {
          const dat = data.data.categories.map((item) => {
            item.value = 1
            return item
          })
          setDataCategory(dat)
        } catch (e) {
          console.log(e)
        }
        formik.setValues({
          name: data.data.name,
          description: data.data.description,
          year: data.data.year,
          measure_date: data.data.measurement_date,
          area: data.data.area
        })
        setLoading(false)
      } catch (error) {
        setLoading(false)
        toast(t(`error.error`), {
          type: toast.TYPE.ERROR
        })
      }
    }
  }
  useEffect(() => {
    if (token) {
      getData()
    }
  }, [token])

  const handlePropertyTypeChange = async (event) => {
    setTypeProperty(event.target.value)
    const data = await AdminService.getTemplateProperty(event.target.value, token)
    setDataCategory(data.data.categories)
  }

  const handleDelete = async (idKpi) => {
    try {
      if (id) {
        const response = await AdminService.deletePropertyCategory(id, token)
        if (response.status === 200) {
          toast(t('error.operacion_exito'), {
            type: toast.TYPE.SUCCESS
          })
          getData()
        }
      } else {
        const catTemp = dataCategory.filter((item) => item.id !== idKpi)
        setDataCategory(catTemp)
      }
    } catch (e) {
      toast(t(`error.error`), {
        type: toast.TYPE.ERROR
      })
    }
  }

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, values } = formik

  return (
    <Page title={t('menu.registro')} loading={loading}>
      <Container maxWidth="xl">
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <ContentStyle>
              <Box mb={3}>
                <Title title={t('register.nueva_prodiedad')} />
              </Box>
              <HeaderComponent title={t('admin.type_property')}>
                <Box sx={{ flexGrow: 1 }} ml={3}>
                  <Grid container>
                    <Grid item xs={4} paddingLeft={2}>
                      <FormControl fullWidth>
                        <Select
                          variant="standard"
                          labelId="rol"
                          value={typeProperty}
                          defaultValue={typeProperty || 1}
                          onChange={(e) => handlePropertyTypeChange(e)}
                          error={Boolean(touched.rol && errors.rol)}
                          helperText={touched.rol && errors.rol}
                        >
                          {propertyTypes.map((p) => (
                            <MenuItem value={p.id} key={`id_property${p.id}`}>
                              {t(`register.${p.name}`)}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={4} paddingLeft={2}>
                      <TitleLabel id="pais">{t('register.pais')}</TitleLabel>
                      <FormControl fullWidth>
                        <Select
                          variant="standard"
                          labelId="pais"
                          label={t('register.pais')}
                          value={country}
                          defaultValue={country}
                          onChange={(e) => setCountry(e.target.value)}
                          error={Boolean(touched.rol && errors.rol)}
                          helperText={touched.rol && errors.rol}
                        >
                          {countries.map((country) => (
                            <MenuItem value={country.id} key={`pais_${country.id}`}>
                              {country.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>
              </HeaderComponent>
              <Box sx={{ mb: 5 }}>
                <Stack spacing={3}>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    {user.greencloud && !id ? (
                      <FormControl fullWidth>
                        <InputLabel id="org_green">{t('register.nombre_propiedad')}</InputLabel>
                        <Select
                          variant="standard"
                          labelId="org_green"
                          color="secondary"
                          label={t('register.pais')}
                          {...getFieldProps('name')}
                          error={Boolean(touched.name && errors.name)}
                          helperText={touched.name && errors.name}
                        >
                          {greenOrg.map((org) => (
                            <MenuItem value={org.id} key={`pais_${org.id}`}>
                              {org.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    ) : (
                      <TextField
                        disabled={id}
                        fullWidth
                        label={t('register.nombre_propiedad')}
                        {...getFieldProps('name')}
                        error={Boolean(touched.name && errors.name)}
                        helperText={touched.name && errors.name}
                      />
                    )}
                  </Stack>
                </Stack>
              </Box>
              <Box sx={{ mb: 5 }}>
                <Stack>
                  <Scrollbar>
                    <TableContainer sx={{ minWidth: 800 }}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell align="left" component="th">
                              {t('register.categoria_reportes')}
                            </TableCell>
                            <TableCell align="left" component="th">
                              {t('register.list_kpis')}
                            </TableCell>
                            <TableCell align="left" component="th">
                              {t('register.origen')}
                            </TableCell>

                            <TableCell align="left" component="th">
                              {t('register.datos_entrada')}
                            </TableCell>
                            <TableCell />
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {dataCategory &&
                            dataCategory.map((row) => (
                              <TableRow hover key={row.id}>
                                <TableCell component="th" scope="row" padding="none">
                                  {t(`register.${row.report_category.name}`)}
                                </TableCell>
                                <TableCell align="left">
                                  {t(`register.${row.kpis_list.name}`)}
                                </TableCell>
                                <TableCell align="left">{t(`register.${row.source}`)}</TableCell>
                                <TableCell align="left">
                                  {t(`register.${row.input_data}`)}
                                </TableCell>
                                <TableCell align="right">
                                  <IconButton onClick={() => handleDelete(row.id)}>
                                    <Delete />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Scrollbar>
                </Stack>
              </Box>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <TextField
                      id="date"
                      fullWidth
                      variant="standard"
                      label={t('register.measure_date')}
                      type="date"
                      {...getFieldProps('measurement_date')}
                      defaultValue={touched.measurement_date}
                      error={Boolean(touched.measurement_date && errors.measurement_date)}
                      helperText={touched.measurement_date && errors.measurement_date}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      variant="standard"
                      label={t('register.anio_base')}
                      {...getFieldProps('year')}
                      error={Boolean(touched.year && errors.year)}
                      helperText={touched.year && errors.year}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      name="area"
                      variant="standard"
                      label={t('admin.area_contruida')}
                      {...getFieldProps('area')}
                      error={Boolean(touched.area && errors.area)}
                      helperText={touched.area && errors.area}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel style={{ marginBottom: '20px' }}>
                      {t('register.descripcion_propiedad')}
                    </InputLabel>
                    <TextField
                      fullWidth
                      rows={4}
                      multiline
                      style={{ width: '100%', fontSize: '16px' }}
                      {...getFieldProps('description')}
                      error={Boolean(touched.description && errors.description)}
                      helperText={touched.description && errors.description}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ flexGrow: 1 }} mt={5}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <LoadingButton
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      loading={isSubmitting}
                    >
                      {t('register.guardar_propiedad')}
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Box>
            </ContentStyle>
          </Form>
        </FormikProvider>
      </Container>
    </Page>
  )
}

Propiedad.propTypes = {
  token: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired
}

export default withAccessGranted(Propiedad, [
  userAccess.ADMIN.ACCESS_LEVEL,
  userAccess.REGISTRO.ACCESS_LEVEL
])
