import React, { useEffect, useState } from 'react'
import {
  Box,
  Container,
  Button,
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Grid,
  Paper,
  TableHead,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  IconButton,
  Card,
  TextField,
  Modal,
  InputAdornment,
  Alert
} from '@mui/material'

import { Check, ArrowBackIos, Add, Save, Cached, Rowing, Edit } from '@mui/icons-material'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import Page from '../../../components/Page'
import HeaderComponent from '../../../components/HeaderComponent'
import RegisterService from '../../../services/RegisterService'
import { withAccessGranted, userAccess } from '../../../components/withAuth'
import AuthContext from '../../../context/AuthContext'
import ModalNote from './modal'

const ContentStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: '20px 0'
}))

const Atras = styled(IconButton)(({ theme }) => ({
  margin: theme.spacing(2),
  fontSize: '14px'
}))

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 420,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4
}

const Data = ({ token }) => {
  const { t } = useTranslation()
  const { id, project } = useParams()
  const [mont, setMont] = useState('')
  const [year, setYear] = useState('')
  const [data, setData] = useState({})
  const [years, setYears] = useState([])
  const [register, setRegister] = useState([])
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const handleNewYear = async () => {
    setLoading(true)
    const data = {
      property: id,
      year
    }
    try {
      await RegisterService.createYear(data, token)
      setYear(year)
      const dataRegister = await RegisterService.getData(id, year, mont, token)
      setData(dataRegister.data)
      const resp = await RegisterService.getYears(id, token)
      setYears(resp.data)
      setYear(year)
      setRegister(dataRegister.data.register)
      setOpen(false)
      setLoading(false)
      toast(t(`error.operacion_exito`), {
        type: toast.TYPE.SUCCESS
      })
    } catch (e) {
      setLoading(false)
      toast(t(`error.${e.response.data.error}`), {
        type: toast.TYPE.ERROR
      })
    }
  }

  const reloadData = async (anio, mont) => {
    try {
      setLoading(true)
      const data = await RegisterService.getData(id, anio, mont, token)
      setData(data.data)
      setRegister(data.data.register)

      setLoading(false)
    } catch (e) {
      setLoading(false)
      toast(t(`error.error`), {
        type: toast.TYPE.ERROR
      })
    }
  }

  const handleYear = (e) => {
    setYear(e.target.value)
    reloadData(e.target.value, mont)
  }

  const handleMonth = (value) => {
    setMont(value)
    reloadData(year, value)
  }

  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true)
        const resp = await RegisterService.getYears(id, token)
        setYears(resp.data)
        const anio = resp.data[0].valor
        setYear(anio)
        const mont = 1
        setMont(mont)
        const data = await RegisterService.getData(id, anio, mont, token)
        setData(data.data)
        setRegister(data.data.register)
        setLoading(false)
      } catch (e) {
        setLoading(false)
        toast(t(`error.error`), {
          type: toast.TYPE.ERROR
        })
      }
    }
    if (token && token !== '') {
      loadData()
    }
  }, [token, id])

  const handleValue = (value, row, pos) => {
    const path = /^[+-]?\d+(\.\d+)?$/
    if (path.test(value) || value === '') {
      const reg = register.map((item) => {
        if (item.id === row.id) {
          item.json_values.values.map((val, i) => {
            if (i === pos) {
              val.value = value
            }
            return val
          })
          return item
        }
        return item
      })
      setRegister(reg)
    }
  }

  const handleValueReload = async (idValue) => {
    try {
      const data = await RegisterService.reeloadGreen(idValue, token)
      if (data.status === 200) {
        const data = await RegisterService.getData(id, year, mont, token)
        setData(data.data)
        setRegister(data.data.register)
        toast(t(`error.operacion_exito`), {
          type: toast.TYPE.SUCCESS
        })
      }
    } catch (e) {
      toast(t(`error.error`), {
        type: toast.TYPE.ERROR
      })
    }
  }
  const loadAllGreencloud = async () => {
    try {
      setLoading(true)
      await RegisterService.reloadAllGreen(project, year, token)
      const mont = 1
      setMont(mont)
      const data = await RegisterService.getData(id, year, mont, token)
      setData(data.data)
      setRegister(data.data.register)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      toast(t(`error.error`), {
        type: toast.TYPE.ERROR
      })
    }
  }

  const handleValueChange = async (row) => {
    try {
      setLoading(true)
      const dat = await RegisterService.handleValue(row.id, row, token)
      if (dat.status === 200) {
        setLoading(false)
        toast(t(`error.operacion_exito`), {
          type: toast.TYPE.SUCCESS
        })
      }
    } catch (e) {
      setLoading(false)
      toast(t(`error.${e.response.data.error}`), {
        type: toast.TYPE.ERROR
      })
    }
  }

  return (
    <Page title={t('menu.registro')} loading={loading}>
      <Card>
        <Container maxWidth="xl">
          <ContentStyle>
            <HeaderComponent title={t('register.registro')} />
            <Box>
              <Grid container spacing={3}>
                <Grid>
                  <Link to={`/register/report/${project}`}>
                    <Atras>
                      <ArrowBackIos style={{ fontSize: '14px' }} /> {t('register.atras')}
                    </Atras>
                  </Link>
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Grid container spacing={3}>
                <Grid item xs={5} mb={4}>
                  {data && (
                    <Typography variant="h6" mt={2}>
                      {data.proyect_name ? data.proyect_name : ''}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={3}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">{t('register.anio')}</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={year}
                      label={t('register.anio')}
                      onChange={(e) => handleYear(e)}
                    >
                      {years.map((year) => (
                        <MenuItem key={`year_valor${year.valor}`} value={year.valor}>
                          {year.valor}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">{t('register.mes')}</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={mont}
                      label="Age"
                      onChange={(e) => handleMonth(e.target.value)}
                    >
                      <MenuItem value={1}>{t('register.enero')}</MenuItem>
                      <MenuItem value={2}>{t('register.febrero')}</MenuItem>
                      <MenuItem value={3}>{t('register.marzo')}</MenuItem>
                      <MenuItem value={4}>{t('register.abril')}</MenuItem>
                      <MenuItem value={5}>{t('register.mayo')}</MenuItem>
                      <MenuItem value={6}>{t('register.junio')}</MenuItem>
                      <MenuItem value={7}>{t('register.julio')}</MenuItem>
                      <MenuItem value={8}>{t('register.agosto')}</MenuItem>
                      <MenuItem value={9}>{t('register.septiembre')}</MenuItem>
                      <MenuItem value={10}>{t('register.octubre')}</MenuItem>
                      <MenuItem value={11}>{t('register.noviembre')}</MenuItem>
                      <MenuItem value={12}>{t('register.diciembre')}</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={1}>
                  <IconButton
                    color="primary"
                    onClick={handleOpen}
                    aria-label={t('register.nuevo_anio')}
                    component="span"
                  >
                    <Add />
                  </IconButton>
                  <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box>
                      <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2" mb={2}>
                          {t('register.nuevo_anio')}
                        </Typography>
                        <TextField
                          id="outlined-basic"
                          label={t('register.anio')}
                          value={year}
                          onChange={(e) => setYear(e.target.value)}
                          variant="outlined"
                        />
                        <Button
                          onClick={() => handleNewYear()}
                          variant="contained"
                          color="primary"
                          size="large"
                          type="button"
                          style={{ marginLeft: '20px', marginTop: '5px' }}
                        >
                          {t('register.crear')}
                        </Button>
                      </Box>
                    </Box>
                  </Modal>
                </Grid>
              </Grid>
            </Box>
            <Box>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>{t('register.categoria_reportes')}</TableCell>
                      <TableCell>{t('register.list_kpis')}</TableCell>
                      <TableCell>{t('register.origen')}</TableCell>
                    </TableRow>
                  </TableHead>
                  {data && data.category && (
                    <TableBody>
                      <TableRow>
                        <TableCell component="td" scope="row">
                          {data.category.report_category
                            ? t(`register.${data.category.report_category.name}`)
                            : ''}
                        </TableCell>
                        <TableCell component="td" scope="row">
                          {data.category.kpis_list.name && (
                            <>{t(`register.${data.category.kpis_list.name}`)}</>
                          )}
                        </TableCell>
                        <TableCell>
                          {data.category.source && <>{t(`register.${data.category.source}`)}</>}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Box>
            <Box
              sx={{
                mt: 3
              }}
            >
              <Grid container spacing={3}>
                <Grid item xs={9}>
                  {data && data.category && (
                    <Typography variant="h6" mt={2}>
                      {t(`register.${data.category.report_category.name}`)}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Box>
            <Box
              sx={{
                mt: 6,
                ml: 2,
                mr: 3
              }}
            >
              <Grid container spacing={3}>
                <Alert severity="warning">{t('register.register_help')}</Alert>
                {register[0] && register[0]?.green && (
                  <Grid item spacing={3} sx={{ position: 'absolute', right: 20, marginTop: -2.4 }}>
                    <IconButton sx={{ float: 'right' }} onClick={loadAllGreencloud}>
                      <Cached style={{ color: '#4cd964' }} />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
            </Box>
            <Box
              sx={{
                mt: 2
              }}
            >
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>{t('register.codigo_kpi')}</TableCell>
                      <TableCell>{t('register.indicador_desempenno')}</TableCell>
                      <TableCell>{t('register.origen_kpi')}</TableCell>
                      <TableCell>{t('register.dato_actividad')}</TableCell>
                      <TableCell>{t('register.unidad_medida')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {register &&
                      register.map((row) => (
                        <TableRow key={`register_${row.id}`}>
                          <TableCell component="th" scope="row">
                            <div style={{ width: '150px', display: 'flex' }}>
                              <IconButton aria-label="edit">
                                <Check fontSize="inherit" />
                              </IconButton>
                              <div style={{ marginTop: '7px' }}>{row.code ? row.code : '-'}</div>
                              <ModalNote {...row} token={token} />
                            </div>
                          </TableCell>
                          <TableCell component="td" scope="row">
                            {row.input_data ? t(`dash.${row.input_data}`) : ''}
                          </TableCell>
                          <TableCell component="td" scope="row">
                            {row.source ? t(`register.${row.source}`) : ''}
                          </TableCell>
                          <AuthContext.Consumer>
                            {({ user }) => (
                              <TableCell component="td" scope="row" style={{ display: 'flex' }}>
                                {row.json_values.values.map((value, i) => (
                                  <div key={`values_${value.id}`} style={{ display: 'flex' }}>
                                    {row.json_values.values.length - 1 === i ? (
                                      <TextField
                                        variant="outlined"
                                        onChange={(e) => handleValue(e.target.value, row, i)}
                                        value={value.value ? value.value : ''}
                                        label={t(`register.${value.label}`)}
                                        style={{ padding: '0 3px', margin: 'auto' }}
                                        size="small"
                                        disabled={row.green}
                                        defaultValue={row.valor}
                                        type="number"
                                        InputProps={{
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              {!row.green ? (
                                                <IconButton
                                                  onClick={() => handleValueChange(row)}
                                                  edge="end"
                                                >
                                                  <Save />
                                                </IconButton>
                                              ) : (
                                                <IconButton
                                                  onClick={() => handleValueReload(row.id)}
                                                  edge="end"
                                                >
                                                  <Cached style={{ color: '#4cd964' }} />
                                                </IconButton>
                                              )}
                                            </InputAdornment>
                                          )
                                        }}
                                      />
                                    ) : (
                                      <TextField
                                        key={`values_${value.id}`}
                                        variant="outlined"
                                        onChange={(e) => handleValue(e.target.value, row, i)}
                                        value={value.value ? value.value : ''}
                                        defaultValue={row.valor ? row.valor : ''}
                                        label={t(`register.${value.label}`)}
                                        InputProps={{
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              <IconButton
                                                disabled={user.user_type !== 1}
                                                onClick={() => handleValueChange(row)}
                                                edge="end"
                                              >
                                                <Save />
                                              </IconButton>
                                            </InputAdornment>
                                          )
                                        }}
                                        style={{ padding: '0 3px' }}
                                        size="small"
                                        disabled={user.user_type !== 1}
                                        type="number"
                                      />
                                    )}
                                  </div>
                                ))}
                              </TableCell>
                            )}
                          </AuthContext.Consumer>
                          <TableCell component="td" scope="row">
                            {t(`register.${row.json_values.name}`)}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </ContentStyle>
        </Container>
      </Card>
    </Page>
  )
}

Data.propTypes = {
  token: PropTypes.string.isRequired
}

export default withAccessGranted(Data, [
  userAccess.ADMIN.ACCESS_LEVEL,
  userAccess.REGISTRO.ACCESS_LEVEL
])
