import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { Link as RouterLink, useLocation } from 'react-router-dom'
// material
import { styled } from '@mui/material/styles'
import { Box, Drawer } from '@mui/material'
// components
import { useTranslation } from 'react-i18next'
import LogoMenu from '../../components/common/LogoMenu'
import Scrollbar from '../../components/Scrollbar'
import NavSection from '../../components/NavSection'
import { MHidden } from '../../components/@material-extend'
//
import { dashboard, admin, registro, usuario } from './menu'
import AuthContext from '../../context/AuthContext'

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280

const RootStyle = styled('div')(({ theme }) => ({
  backgroundColor: 'red',
  background: 'linear-gradient(90deg, #15BD85 0%, #F7CA18 100%), #FFFFFF;',
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH
  }
}))

const Item = styled(Box)(({ theme }) => ({
  color: '#FFFFFF',
  fontSize: 16,
  paddingTop: '2px!important',
  borderBottom: '1px solid #FFFFFF'
}))

const SubItem = styled(Box)(({ theme }) => ({
  color: '#FFFFFF',
  fontFamily: 'Open Sans',
  fontWeight: '400',
  fontSize: 14,
  lineHeight: '19px'
}))

// ----------------------------------------------------------------------

const Sidebar = ({ isOpenSidebar, onCloseSidebar, type }) => {
  const { t } = useTranslation()
  const [menu, setMenu] = useState([])
  const [label, setLabel] = useState('')
  const handleMenu = (type) => {
    if (type === 'dashboard') {
      setMenu(dashboard)
      setLabel('dashboard')
    } else if (type === 'admin') {
      setMenu(admin)
      setLabel('administration')
    } else if (type === 'data') {
      setMenu(registro)
      setLabel('registro')
    } else if (type === 'users') {
      setMenu(usuario)
      setLabel('gestion_usuarios')
    }
  }
  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar()
    }
    handleMenu(type)
  }, [type])

  const renderContent = (
    <nav>
      <Scrollbar
        sx={{
          height: '100%',
          '& .simplebar-content': { height: '100%', display: 'flex', flexDirection: 'column' }
        }}
      >
        <Box sx={{ px: 2.5, py: 3 }} style={{ margin: '10px auto' }}>
          <Box
            component={RouterLink}
            to="/dashboard/app"
            sx={{ display: 'inline-flex' }}
            state={{ padding: '30px' }}
          >
            <LogoMenu />
          </Box>
        </Box>
        <AuthContext.Consumer>
          {({ user }) => (
            <>
              <Item sx={{ px: 2, py: 3 }}>{t('menu.nombre_usuario')}:</Item>
              <SubItem sx={{ px: 2, py: 3 }}>{user.name}</SubItem>
              <Item sx={{ px: 2, py: 3 }}>{t('menu.tipo_usuario')}:</Item>
              <SubItem sx={{ px: 2, py: 3 }}>
                {user.user_type === 1 ? t('menu.administrador') : t('menu.usuario')}
              </SubItem>
            </>
          )}
        </AuthContext.Consumer>
        <NavSection navConfig={menu} label={label} />
        <Box sx={{ flexGrow: 1 }} />
      </Scrollbar>
    </nav>
  )

  return (
    <RootStyle>
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: '#15BD85',
              bg: ''
            }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'transparent',
              bg: 'linear-gradient(90deg, #15BD85 0%, #F7CA18 100%), #FFFFFF'
            }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  )
}

Sidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
  type: PropTypes.string
}

export default Sidebar
