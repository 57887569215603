// material
import { useState, useEffect } from 'react'
import {
  Grid,
  Container,
  Stack,
  Box,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Typography,
  Checkbox,
  ListItemText
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { PropTypes } from 'prop-types'
import { toast } from 'react-toastify'
import { withAccessGranted, userAccess } from '../../components/withAuth'

import AdminService from '../../services/Admin/index'
// components
import Page from '../../components/Page'
import Title from '../../components/Title'
import Catalog from './component/catalog'
import Consolidado from './component/Consolidado'

const ReporteAnual = ({ token = '' }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState([])
  const [catalogo, setCatalogo] = useState(0)
  const [years, setYears] = useState([])
  const [nameProperty, setNameProperty] = useState('')
  const [typeProperty, setTypeProperty] = useState([])
  const [property, setProperty] = useState()
  const [year, setYear] = useState([])
  const [dataIndicator, setDataIndicator] = useState([])
  const [projectName, setProjectName] = useState('')
  const [um, setUm] = useState('')

  const [unidad, setUnidad] = useState('')

  const handleUnidad = (unidad) => {
    setUnidad(unidad)
  }

  const getData = async () => {
    try {
      setLoading(true)
      const typeData = await AdminService.getProperties(token)
      setTypeProperty(typeData.data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const getDataAnual = async (property, year, catalogo) => {
    try {
      if (year.length === 2) {
        setLoading(true)
        const dataIndicador = await AdminService.getDataIndicadorYearConsolidate(
          property,
          year[0],
          year[1],
          catalogo,
          token
        )
        setDataIndicator(dataIndicador.data.data)
        setNameProperty(dataIndicador.data.name)
        setUm(dataIndicador.data.um)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
    }
  }

  const reloadData = (catalog) => {
    setCatalogo(catalog)
  }

  useEffect(() => {
    if (token) {
      getData()
    }
  }, [token])

  useEffect(() => {
    if (property && year && catalogo && unidad) {
      getDataAnual(property, year, catalogo)
    }
  }, [property, year, catalogo, unidad])

  const handleProperty = async (property) => {
    try {
      setLoading(true)
      setProperty(property)
      if (token && token !== '') {
        const yearData = await AdminService.getAllYear(token, property)
        setYears(yearData.data)
        setProjectName(yearData.name)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const handleYear = (e) => {
    e.preventDefault()
    const { value } = e.target
    if (value.length < 3) {
      setYear(value)
    } else {
      toast(t('dash.solo_2_anios'), { type: 'error' })
    }
  }

  return (
    <Page title="Dashboard" loading={loading}>
      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Title title={t('dash.reporte_mensual')} />
        </Stack>
        <Box sx={{ flexGrow: 1 }} pt={2} pb={2}>
          <Grid container style={{ borderBottom: ' solid 1px #D8D8D8' }}>
            <Grid item xs={3} md={3}>
              <b> {t('dash.seleccione_propiedad')}</b>
            </Grid>
            <Grid item xs={3} paddingLeft={2}>
              <FormControl fullWidth>
                <InputLabel id="anio">{t('dash.seleccione_una_opcion')}</InputLabel>
                <Select
                  labelId="residuos"
                  id="demo-simple-select"
                  label="Rol"
                  value={property}
                  variant="standard"
                  onChange={(e) => handleProperty(e.target.value)}
                >
                  {typeProperty.map((item) => (
                    <MenuItem key={`item_${item.id}`} value={item.id}>
                      <ListItemText primary={item.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3} ml={2}>
              <FormControl fullWidth>
                <InputLabel id="anio">{t('dash.seleccione_dos_periodos')}</InputLabel>
                <Select
                  labelId="anio"
                  id="demo-simple-select"
                  label="Rol"
                  variant="standard"
                  value={year}
                  multiple
                  onChange={(e) => handleYear(e)}
                  renderValue={(selected) => selected.join(', ')}
                >
                  {years.map((item) => (
                    <MenuItem key={`item_${item.year}`} value={item.year}>
                      <Checkbox checked={year.indexOf(item.year) > -1} />
                      <ListItemText primary={item.year} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        <Grid container spacing={3} mt={4}>
          <Grid item xs={12} md={12} lg={12}>
            <Catalog reloadData={reloadData} catalogo={catalogo} handleUnidad={handleUnidad} />
          </Grid>
          {dataIndicator.length > 0 && (
            <Grid item xs={12}>
              <Typography variant="h4" gutterBottom>
                {t('dash.kpis_anueles')}
              </Typography>
            </Grid>
          )}
          {dataIndicator &&
            dataIndicator
              .filter((p) => p[0].input_data === unidad)
              .map((item, id) => (
                <Grid item xs={12} md={12} key={`id_${id}`} lg={12}>
                  <Consolidado
                    nameProperty={nameProperty}
                    data={item[0]}
                    project={projectName}
                    unidadMedida={um}
                    name={item[0].input_data}
                    year1={year[0]}
                    year2={year[1]}
                  />
                </Grid>
              ))}
        </Grid>
      </Container>
    </Page>
  )
}

ReporteAnual.propTypes = {
  token: PropTypes.string
}

export default withAccessGranted(ReporteAnual, [
  userAccess.ADMIN.ACCESS_LEVEL,
  userAccess.REGISTRO.ACCESS_LEVEL
])
