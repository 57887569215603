import { Icon } from '@iconify/react'
import { useRef, useState } from 'react'
import editFill from '@iconify/icons-eva/edit-fill'
import { Link as RouterLink } from 'react-router-dom'
import trash2Outline from '@iconify/icons-eva/trash-2-outline'
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'

// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material'
import { useTranslation } from 'react-i18next'
import UserService from '../../../../services/UserService'

// ----------------------------------------------------------------------

const UserMenu = ({ id, token, loadData }) => {
  const ref = useRef(null)
  const { t } = useTranslation()

  const [isOpen, setIsOpen] = useState(false)

  const handleDelete = async () => {
    try {
      const data = await UserService.deleteUser(id, token)
      if (data.status === 200) {
        toast(t(`error.operacion_exito`), {
          type: toast.TYPE.SUCCESS
        })
        loadData()
      }
    } catch (e) {
      console.log(e.response)
      if (e.response.data.error) {
        toast(t(`error.${e.response.data.error}`), {
          type: toast.TYPE.ERROR
        })
      } else {
        toast(t(`error.error`), {
          type: toast.TYPE.ERROR
        })
      }
    }
  }

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem component={RouterLink} to={`/users/user/${id}`} sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Icon icon={editFill} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary={t('user.edit')} primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
        <MenuItem sx={{ color: 'text.secondary' }}>
          <ListItemIcon onClick={() => handleDelete()}>
            <Icon icon={trash2Outline} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary={t('user.delete')} primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
      </Menu>
    </>
  )
}
UserMenu.propTypes = {
  token: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  loadData: PropTypes.func.isRequired
}
export default UserMenu
