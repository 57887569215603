import { Box, Grid, Stack } from '@mui/material'
import { Instagram, Facebook, LinkedIn } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

import styled from '@emotion/styled'

const Item = styled('div')({
  overflow: 'hidden',
  fontStyle: 'normal',
  padding: '0 2rem',
  '& h1': {
    fontWeight: '800',
    fontSize: '20px',
    lineHeight: '27px',
    marginBottom: '0.5rem',
    color: '#F7CA18'
  },
  '& a': {
    color: '#454545',
    fontSize: '16px',
    textDecoration: 'none'
  },
  '& p': {
    fontWeight: '400',
    fontSize: '16px',
    color: '#454545',
    lineHeight: '40px',
    padding: '5px 0',
    cursor: 'pointer'
  }
})
const FooterWrapper = styled('footer')({
  padding: '0 2rem'
})
const FooterIcons = styled('div')({
  display: 'flex',
  '& svg': {
    width: '40px',
    height: '40px',
    margin: '0 20px',
    color: '#15BD85',
    cursor: 'pointer'
  }
})

const Footer = () => {
  const { t } = useTranslation()
  return (
    <FooterWrapper className="footer">
      <Stack direction="row" alignItems="center" spacing={2}>
        <Box
          component="img"
          src="/static/logo.png"
          sx={{ width: 200, height: 'auto' }}
          ml={3}
          mb={3}
        />
      </Stack>
      <Stack direction="row">
        <Grid container rowSpacing={0} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} md={3}>
            <Item style={{ padding: '0 25px' }}>
              <h1>{t('footer.conectemonos')}</h1>
              <p>
                <a
                  href="mailto: info@greencloud.io subject=Support "
                  target="_blank"
                  rel="noreferrer"
                >
                  C.: info@greencloud.io
                </a>
              </p>
              <p>
                <a href="tel:+50684810595" target="_blank" rel="noreferrer">
                  T.: + 506 - 8481-0595
                </a>
              </p>
            </Item>
          </Grid>
          <Grid item xs={12} md={3}>
            <Item>
              <h1>{t('footer.nosotros')}</h1>
              <p>{t('footer.quienes_somos')}</p>
              <p>{t('footer.que_hacemos')}</p>
            </Item>
          </Grid>
          <Grid item xs={12} md={3} alignItems="center">
            <Item>
              <h1>Integraciones</h1>
              <p>
                <a href="https://greencloud.io" target="_blank" rel="noreferrer">
                  GreenCloud
                </a>
              </p>
              <p> </p>
              <p> </p>
              <p> </p>
              <p> </p>
            </Item>
          </Grid>
          <Grid item xs={12} md={3}>
            <Item>
              <h1>Soporte</h1>
              <p>FAQs</p>
            </Item>
          </Grid>
        </Grid>
      </Stack>
      <Stack direction="row">
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={3} padding={{ xs: 0, sm: 2 }} mt={{ xs: 1, sm: 2 }}>
            <FooterIcons>
              <a>
                <Instagram />
              </a>
              <a>
                <Facebook />
              </a>
              <a>
                <LinkedIn />
              </a>
            </FooterIcons>
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={12} md={3} justifyContent="flex-end" alignItems="center">
            <div style={{ marginTop: '25px', textAlign: 'center' }}>
              Made with 💚 by GreenCloud®
            </div>
          </Grid>
        </Grid>
      </Stack>
    </FooterWrapper>
  )
}
export default Footer
