import { useEffect, useState } from 'react'
import { LoadingButton } from '@mui/lab'
import * as Yup from 'yup'
import Select from '@mui/material/Select'
import FormControl from '@mui/material/FormControl'
import { toast } from 'react-toastify'
import { Form, FormikProvider, useFormik } from 'formik'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'

import {
  Box,
  Stack,
  Container,
  TextField,
  InputLabel,
  MenuItem,
  FormHelperText,
  Grid
} from '@mui/material'
import { styled } from '@mui/material/styles'

import { useTranslation } from 'react-i18next'
import UserService from '../../../services/UserService'
import Page from '../../../components/Page'
import HeaderComponent from '../../../components/HeaderComponent'
import Title from '../../../components/Title'
import { withAccessGranted, userAccess } from '../../../components/withAuth'

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 800,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center'
}))

// ----------------------------------------------------------------------

const Profile = ({ token, user }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [usersType, setUsersType] = useState([])
  const ProfileSchema = Yup.object().shape({
    name: Yup.string(),
    email: Yup.string(),
    user_type: Yup.number(),
    password: Yup.string().required(t('error.requiered_field')),
    password_repeat: Yup.string().required(t('error.requiered_field'))
  })
  const handleForm = async (values) => {
    if (values.password !== values.password_repeat) {
      toast(t('error.invalid_password_repeat'), {
        type: toast.TYPE.ERROR
      })
      return
    }
    try {
      const response = await UserService.usersChangePass(values.email, values.password, token)
      if (response.status === 200) {
        toast(t('success.success'), {
          type: toast.TYPE.SUCCESS
        })
      }
    } catch ({ response }) {
      if (response.data.error) {
        toast(t(`error.${response.data.error}`), {
          type: toast.TYPE.ERROR
        })
      } else {
        toast(t(`error.error`), {
          type: toast.TYPE.ERROR
        })
      }
    }
  }

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      user_type: '',
      password_repeat: '',
      password: ''
    },
    validationSchema: ProfileSchema,
    onSubmit: handleForm
  })
  const getData = async () => {
    const usersTest = await UserService.getUsersType(token)
    setUsersType(usersTest.data)
  }

  useEffect(() => {
    if (token && token !== '' && user && user !== '') {
      getData()
      formik.setValues({
        name: user.name,
        email: user.email,
        user_type: user.user_type
      })
    }
  }, [token, user])

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik

  return (
    <Page title={t('menu.user')}>
      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Title title={t('user.profile')} />
        </Stack>
        <HeaderComponent />
        <ContentStyle>
          <Box sx={{ mb: 4, mt: 2 }}>
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack spacing={3}>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <TextField
                      fullWidth
                      disabled
                      label={t('user.nombre')}
                      {...getFieldProps('name')}
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                    />
                    <FormControl fullWidth>
                      <InputLabel id="rol">{t('user.rol_acceso')}</InputLabel>
                      <Select
                        labelId="rol"
                        disabled
                        id="demo-simple-select"
                        {...getFieldProps('user_type')}
                        label="Rol"
                        error={Boolean(touched.rol && errors.rol)}
                        helperText={touched.rol && errors.rol}
                      >
                        {usersType.map((user) => (
                          <MenuItem key={`key_${user.id}`} value={user.id}>
                            {t(`user.${user.nombre}`)}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText error={touched.rol && errors.rol}>
                        {errors.rol}
                      </FormHelperText>
                    </FormControl>
                  </Stack>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <TextField
                      fullWidth
                      disabled
                      label={t('user.email_acceso')}
                      {...getFieldProps('email')}
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                    />
                  </Stack>

                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <TextField
                      type="password"
                      fullWidth
                      label={t('user.password')}
                      {...getFieldProps('password')}
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                    />
                    <TextField
                      type="password"
                      fullWidth
                      label={t('user.password_repeat')}
                      {...getFieldProps('password_repeat')}
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                    />
                  </Stack>
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid container xs={12} spacing={2} alignItems="center">
                      <Grid item xs={4} alignItems="center">
                        <LoadingButton
                          fullWidth
                          size="large"
                          type="submit"
                          variant="contained"
                          loading={isSubmitting}
                        >
                          {t('user.guardar')}
                        </LoadingButton>
                      </Grid>
                    </Grid>
                  </Box>
                </Stack>
              </Form>
            </FormikProvider>
          </Box>
        </ContentStyle>
      </Container>
    </Page>
  )
}

Profile.propTypes = {
  token: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired
}

export default withAccessGranted(Profile, [
  userAccess.ADMIN.ACCESS_LEVEL,
  userAccess.REGISTRO.ACCESS_LEVEL
])
