import { Card, CardHeader } from '@mui/material'
import { Box } from '@mui/system'
import { PropTypes } from 'prop-types'
import React, { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import { useTranslation } from 'react-i18next'

const Consolidate = ({ name, data, year, project, nameProperty, unidadMedida = 'kWh' }) => {
  const { t } = useTranslation()
  const [options, setOptions] = useState({})
  const [series, setSeries] = useState([])

  useEffect(() => {
    try {
      const dataResp = data.map((item) => parseFloat(item.valor.toFixed(2)))
      const dataResp1 = data.map((item) => (item.valor1 ? parseFloat(item.valor1.toFixed(2)) : 0))

      const series = [
        {
          name: `${t(`dash.${name}`)} (${year})`,
          type: 'column',
          data: dataResp
        },
        {
          name: `${t(`dash.${name}`)} (${year + 1})`,
          type: 'column',
          data: dataResp1
        }
      ]

      setSeries(series)
      const options = {
        chart: {
          height: 350,
          type: 'line',
          stacked: false,
          toolbar: {
            show: true,
            offsetX: -10,
            offsetY: -10,
            tools: {
              download: true,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
              customIcons: []
            }
          }
        },
        stroke: {
          width: [0, 2, 5]
        },
        colors: ['#808080', '#F7CA18'],
        dataLabels: {
          enabled: true,
          offsetX: -6,
          style: {
            fontSize: '12px'
          }
        },
        xaxis: {
          categories: [
            `${t('dash.mes1')}`,
            `${t('dash.mes2')}`,
            `${t('dash.mes3')}`,
            `${t('dash.mes4')}`,
            `${t('dash.mes5')}`,
            `${t('dash.mes6')}`,
            `${t('dash.mes7')}`,
            `${t('dash.mes8')}`,
            `${t('dash.mes9')}`,
            `${t('dash.mes10')}`,
            `${t('dash.mes11')}`,
            `${t('dash.mes12')}`
          ],
          position: 'bottom',
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          crosshairs: {
            fill: {
              type: 'gradient',
              gradient: {
                colorFrom: '#D8E3F0',
                colorTo: '#BED1E6',
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5
              }
            }
          }
        },
        yaxis: [
          {
            axisTicks: {
              show: true
            },
            title: {
              text: t(`dash.${name}`)
            }
          }
        ],
        title: {
          text: `${nameProperty}: ${t(`dash.${name}`)} (${t(`register.${unidadMedida}`)}) / ${t(
            'dash.havitaciones_ocupadas'
          )} - ${year}`,
          floating: false,
          offsetY: 10,
          align: 'center',
          style: {
            color: '#444'
          }
        }
      }
      setOptions(options)
    } catch (e) {
      console.log(e)
    }
  }, [name, data])
  return (
    <Card>
      <CardHeader title={`${nameProperty} - ${year}`} subheader={project || ''} />
      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <div id="chart">
          <ReactApexChart options={options} series={series} type="line" height={350} />
        </div>
      </Box>
    </Card>
  )
}

Consolidate.propTypes = {
  name: PropTypes.string,
  data: PropTypes.array,
  year: PropTypes.number,
  project: PropTypes.string
}
Consolidate.defaultProps = {
  name: '',
  data: [],
  year: 0,
  project: ''
}

export default Consolidate
