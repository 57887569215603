// ----------------------------------------------------------------------
const dashboard = [
  {
    title: 'dashboard',
    children: [
      {
        path: '/dashboard/app',
        title: 'consulta_propiedad',
        rol: 3
      },
      {
        path: '/dashboard/year',
        title: 'consulta_anual',
        rol: 3
      },
      {
        path: '/dashboard/consolidate',
        title: 'consulta_calculada',
        rol: 3
      },
      {
        path: '/dashboard/report-month',
        title: 'reporte_mensual',
        rol: 3
      },
      {
        path: '/dashboard/report-consolidate',
        title: 'reporte_consolidado',
        rol: 3
      }
    ]
  }
]

const admin = [
  {
    title: 'configuraciones',
    path: '/admin',
    children: [
      {
        path: '/admin/community',
        title: 'perfil_organizacion',
        rol: 1
      },
      {
        path: '/admin/property/list',
        rol: 3,
        title: 'configuracion_propiedades'
      }
    ]
  }
]

const usuario = [
  {
    title: 'gestion_usuarios',
    path: '/users/list',
    children: [
      {
        path: '/users/list',
        title: 'lista_usuarios',
        rol: 1
      },
      {
        path: '/users/user',
        title: 'user',
        padre: '/users/list',
        rol: 1
      },
      {
        path: '/users/profile',
        title: 'profile',
        padre: '/users/profile',
        rol: 3
      }
    ]
  }
]

const registro = [
  {
    title: 'gestion_propiedades',
    path: '/dashboard/app',
    children: [
      {
        register: true,
        path: '/register/list',
        title: 'lista_proyectos'
      }
    ]
  }
]

export { dashboard, admin, usuario, registro }
