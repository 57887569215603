import PropTypes from 'prop-types'
// material
import { Box } from '@mui/material'
import styled from '@emotion/styled'

// ----------------------------------------------------------------------
const Img = styled(Box)(({ theme }) => ({
  width: 140,
  height: 'auto',
  margin: theme.spacing(1, 1),
  cursor: 'pointer',
  [theme.breakpoints.down('md')]: {
    width: 120
  }
}))

const Logo = () => <Img component="img" src="/static/logo.png" />

Logo.propTypes = {
  sx: PropTypes.object
}

export default Logo
