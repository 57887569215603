/* eslint-disable camelcase */
import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import Button from '@mui/material/Button'
import { Close, EventNote } from '@mui/icons-material'
import { CardContent, Grid, IconButton, TextField, Tooltip, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useTranslation } from 'react-i18next'
import RegisterService from '../../../services/RegisterService'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 800,
  minHeight: 300,
  maxHeight: '90%',
  overflow: 'scroll',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3
}

const styleSubmodel = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 600,
  minHeight: 300,
  maxHeight: '90%',
  overflow: 'scroll',
  bgcolor: 'background.paper',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3
}

function ChildModal({ token, id, reload }) {
  const [open, setOpen] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [text, setText] = useState('')
  const { t } = useTranslation()
  const handleOpen = () => {
    setIsSubmitting(false)
    setText('')
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const handleNote = async () => {
    setIsSubmitting(true)
    try {
      const data = {
        register: id,
        text
      }
      await RegisterService.createNote(data, token)
      reload()
      handleClose()
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <Tooltip title="Notas">
        <Button aria-label="edit" onClick={handleOpen}>
          <EventNote fontSize="inherit" />
          <span style={{ marginLeft: '10px' }}> Nueva nota</span>
        </Button>
      </Tooltip>
      <Modal
        hideBackdrop
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...styleSubmodel }}>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Nueva nota
              </Typography>
            </Grid>
            <Grid item xs={4} mb={10} sx={{ display: 'flex' }}>
              <IconButton
                sx={{ right: 5, top: 1, position: 'absolute' }}
                color="primary"
                aria-label="upload picture"
                onClick={handleClose}
                component="label"
              >
                <Close sx={{ color: '#121212' }} />
              </IconButton>
            </Grid>
          </Grid>
          <p id="child-modal-description">
            <TextField
              id="outlined-textarea"
              fullWidth
              label="Nota"
              maxRows={4}
              value={text}
              onChange={(e) => setText(e.target.value)}
              placeholder="Nota"
              multiline
              sx={{ mb: 2 }}
            />
          </p>
          <Box sx={{ textAlign: 'center' }}>
            <LoadingButton
              size="large"
              onClick={handleNote}
              type="button"
              variant="contained"
              loading={isSubmitting}
            >
              {t('user.guardar')}
            </LoadingButton>
          </Box>
        </Box>
      </Modal>
    </>
  )
}

const Notas = ({ source, year, json_values, code, id, token }) => {
  const { t } = useTranslation()
  const { name } = json_values
  const [note, setNote] = useState()
  const [open, setOpen] = React.useState(false)
  const handleOpen = async () => {
    const notes = await RegisterService.getNote(id, token)
    setNote(notes.data)
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const reload = async () => {
    const notes = await RegisterService.getNote(id, token)
    setNote(notes.data)
  }

  return (
    <div>
      <Tooltip title="Notas">
        <IconButton aria-label="edit" onClick={handleOpen}>
          <EventNote fontSize="inherit" />
        </IconButton>
      </Tooltip>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style }}>
          <Grid container spacing={2}>
            <Grid item xs={8} ml={2} mt={2}>
              <Typography id="modal-modal-title" variant="h6" component="h3">
                Notas: {code} - {t(`register.${source}`)} {year}
              </Typography>
              <Grid mt={1} ml={-1}>
                <ChildModal id={id} token={token} reload={reload} />
              </Grid>
            </Grid>
            <Grid item xs={4} sx={{ display: 'flex' }}>
              <IconButton
                sx={{ right: 5, top: 1, position: 'absolute' }}
                color="primary"
                aria-label="upload picture"
                onClick={handleClose}
                component="label"
              >
                <Close sx={{ color: '#121212' }} />
              </IconButton>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ overflow: 'scroll' }}>
            {note &&
              note.map((item) => (
                <Grid item xs={12} key={`nota_${item.id}`}>
                  <CardContent>
                    <Typography variant="h5" component="div">
                      {t(`register.${name}`)}
                    </Typography>
                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                      {item.created_date}
                    </Typography>
                    <Typography variant="body2">{item.text}</Typography>
                  </CardContent>
                </Grid>
              ))}
          </Grid>
        </Box>
      </Modal>
    </div>
  )
}
export default Notas
