import { useEffect, useState } from 'react'
import { LoadingButton } from '@mui/lab'
import * as Yup from 'yup'
import Select from '@mui/material/Select'
import FormControl from '@mui/material/FormControl'
import { useTranslation } from 'react-i18next'
import { Form, FormikProvider, useFormik } from 'formik'
import PropTypes from 'prop-types'

import {
  Box,
  Stack,
  Container,
  TextField,
  InputLabel,
  MenuItem,
  FormHelperText,
  Card,
  Grid
} from '@mui/material'
import { styled } from '@mui/material/styles'

// components

import { toast } from 'react-toastify'
import Page from '../../../components/Page'
import HeaderComponent from '../../../components/HeaderComponent'
import AdminService from '../../../services/Admin/index'
import { withAccessGranted, userAccess } from '../../../components/withAuth'

const ContentStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: '20px 0'
}))

// ----------------------------------------------------------------------

const Community = ({ token, user }) => {
  const { t } = useTranslation()
  const [selected, setSelected] = useState([])
  const [countries, setCountries] = useState([])
  const [typeLodgings, setTypeLodgings] = useState([])
  const [organization, setOrganization] = useState({})
  const [loading, setLoading] = useState(false)
  const [properties, setProperties] = useState([])

  const RegisterSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, t('error.too_short'))
      .max(50, t('error.too_long'))
      .required(t('error.value_requiered')),
    type_lodging: Yup.number().required(t('error.value_requiered')),
    no_user: Yup.number().required(t('error.value_requiered')),
    property_number: Yup.string().required(t('error.value_requiered')),
    contact_name: Yup.string().required(t('error.value_requiered')),
    contact_phone: Yup.string().required(t('error.value_requiered')),
    country: Yup.number().required(t('error.value_requiered'))
  })

  const handleSubmitComunity = async (values) => {
    await AdminService.updateOrganization(1, values, token)
  }

  const formik = useFormik({
    initialValues: {
      name: '',
      type_lodging: 0,
      no_user: '',
      contact_name: '',
      contact_phone: 0,
      country: 0,
      property_number: ''
    },
    validationSchema: RegisterSchema,
    onSubmit: handleSubmitComunity
  })

  useEffect(() => {
    if (token) {
      getData()
    }
  }, [token, user])

  const getData = async () => {
    try {
      setLoading(true)
      const [country, getTypeLodging, organization, property] = await Promise.all([
        AdminService.getCountry(token),
        AdminService.getTypeLodging(token),
        AdminService.getOrganization(user.organization, token),
        AdminService.getPropertyType(token)
      ])
      setCountries(country.data)
      setTypeLodgings(getTypeLodging.data)
      formik.setValues(organization.data)
      setOrganization(organization.data)
      setProperties(property.data)
      setLoading(false)
    } catch (err) {
      setLoading(false)
      toast(t(`error.error`), {
        type: toast.TYPE.ERROR
      })
    }
  }

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, values } = formik

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name)
    let newSelected = []
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }
    setSelected(newSelected)
  }

  return (
    <Page title={t('menu.user')} loading={loading}>
      <Card>
        <Container maxWidth="xl">
          <ContentStyle>
            <HeaderComponent type title={t('admin.informacion_organizacion')} />
            <Box sx={{ mb: 5 }}>
              <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                  <Stack spacing={3}>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                      <TextField
                        fullWidth
                        label={t('admin.nombre_organizacion')}
                        {...getFieldProps('name')}
                        error={Boolean(touched.name && errors.name)}
                        helperText={touched.name && errors.name}
                      />
                      <FormControl fullWidth>
                        <InputLabel id="type_lodging">{t('admin.tipo_hospedaje')}</InputLabel>
                        <Select
                          labelId="rol"
                          id="type_lodging"
                          {...getFieldProps('type_lodging')}
                          label={t('admin.tipo_hospedaje')}
                          error={Boolean(touched.type_lodging && errors.type_lodging)}
                          helperText={touched.type_lodging && errors.type_lodging}
                        >
                          {typeLodgings.map((item) => (
                            <MenuItem key={`item_${item.id}`} value={item.id}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText error={touched.type_lodging && errors.type_lodging}>
                          {errors.type_lodging}
                        </FormHelperText>
                      </FormControl>
                    </Stack>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                      <TextField
                        fullWidth
                        label={t('admin.no_usuario')}
                        value={organization.no_user}
                        {...getFieldProps('no_user')}
                        error={Boolean(touched.no_user && errors.no_user)}
                        helperText={touched.no_user && errors.no_user}
                      />
                      <TextField
                        fullWidth
                        name="property_number"
                        type="text"
                        label={t('admin.no_propiedad')}
                        {...getFieldProps('property_number')}
                        error={Boolean(touched.property_number && errors.property_number)}
                        helperText={touched.property_number && errors.property_number}
                      />
                    </Stack>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                      <FormControl fullWidth>
                        <InputLabel id="countryLabel">{t('admin.pais_base_referencia')}</InputLabel>
                        <Select
                          labelId="countryLabel"
                          name="country"
                          value={organization.countryLabel}
                          id="country_select"
                          {...getFieldProps('country')}
                          label={t('admin.pais_base_referencia')}
                          error={Boolean(touched.country && errors.country)}
                          helperText={touched.country && errors.country}
                        >
                          {countries.map((item) => (
                            <MenuItem key={`item_${item.id}`} value={item.id}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText error={touched.country && errors.country}>
                          {errors.country}
                        </FormHelperText>
                      </FormControl>
                      <TextField
                        fullWidth
                        value={organization.contact_phone}
                        label={t('admin.telefono_contacto')}
                        {...getFieldProps('contact_phone')}
                        error={Boolean(touched.contact_phone && errors.contact_phone)}
                        helperText={touched.contact_phone && errors.contact_phone}
                      />
                    </Stack>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                      <TextField
                        fullWidth
                        value={organization.contact_name}
                        label={t('admin.nombre_contacto')}
                        {...getFieldProps('contact_name')}
                        error={Boolean(touched.contact_name && errors.contact_name)}
                        helperText={touched.contact_name && errors.contact_name}
                      />
                    </Stack>
                    <Box sx={{ flexGrow: 1 }}>
                      <Grid container xs={12} spacing={2} alignItems="center">
                        <Grid item xs={4} alignItems="center">
                          <LoadingButton
                            size="large"
                            fullWidth
                            type="submit"
                            variant="contained"
                            loading={isSubmitting}
                          >
                            {t('admin.guardar')}
                          </LoadingButton>
                        </Grid>
                      </Grid>
                    </Box>
                  </Stack>
                </Form>
              </FormikProvider>
            </Box>
          </ContentStyle>
        </Container>
      </Card>
    </Page>
  )
}

Community.propTypes = {
  token: PropTypes.string.isRequired
}

export default withAccessGranted(Community, [
  userAccess.ADMIN.ACCESS_LEVEL,
  userAccess.REGISTRO.ACCESS_LEVEL
])
