import { PropTypes } from 'prop-types'
import { Card, CardHeader, Box } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

// ----------------------------------------------------------------------

const Consolidate = ({ data, nameProperty, name, unidadMedida, year1, year2 }) => {
  const { t } = useTranslation()
  const [subheader, setSubheader] = useState('')

  useEffect(() => {
    setSubheader(`${t(`dash.${name}`)} - (${t(`register.${unidadMedida}`)})`)
  }, [data])

  const { valor, valor1, goal, goal_value: goalValue } = data
  return (
    <Card>
      <CardHeader title={`KPIs ${year1} ${nameProperty}`} subheader={subheader || ''} />
      <Box sx={{ p: 3, pb: 10 }} dir="ltr">
        <div className="row">
          <div>
            <h2>
              {t(`dash.${name}`)} - ({t(`register.${unidadMedida}`)})
            </h2>
            <table style={{ width: '100%' }}>
              <thead>
                <th style={{ textAlign: 'left' }}>{t('dash.periodo')}</th>
                <th style={{ textAlign: 'left' }}>{t('dash.unidad_medidad')}</th>
                <th style={{ textAlign: 'left' }}>{t('dash.valor')}</th>
                <th style={{ textAlign: 'left' }}>{t('register.consulta_calculada')} (%)</th>
              </thead>
              <tbody>
                <tr>
                  <td>{year1}</td>
                  <td>{t(`register.${unidadMedida}`)}</td>
                  <td>{parseFloat(valor.toFixed(2)).toLocaleString('en-US')}</td>
                  <td>{parseFloat(100).toLocaleString('en-US')} %</td>
                </tr>
                <tr>
                  <td>{year2}</td>
                  <td>{t(`register.${unidadMedida}`)}</td>
                  <td> {parseFloat(valor1.toFixed(2)).toLocaleString('en-US')}</td>
                  <td>
                    {parseFloat(parseFloat((valor1 * 100) / valor - 100).toFixed(2)).toLocaleString(
                      'en-US'
                    )}{' '}
                    %
                  </td>
                </tr>
                {year1 === parseInt(year2 - 1, 10) && (
                  <tr>
                    <td>
                      {t('dash.objetivo')} ({year2})
                    </td>
                    <td>{t(`register.${unidadMedida}`)}</td>
                    <td>{parseFloat(goal.toFixed(2)).toLocaleString('en-US')}</td>
                    <td>{parseFloat((goalValue * -1).toFixed(2)).toLocaleString('en-US')} %</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </Box>
    </Card>
  )
}
Consolidate.propType = {
  data: PropTypes.object
}
Consolidate.defaultProps = {
  trimestre: 0,
  data: {}
}

export default Consolidate
